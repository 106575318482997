import { useContext } from 'react';
import { RegionSelectionContext } from 'components/header-nav/region-selection-context';

export type CurrentUbkHeader = {
  headers: {} | { Brandubk: string };
};

export const useCurrentRegionUbkHeader = (): CurrentUbkHeader => {
  const { selectedRegion } = useContext(RegionSelectionContext);
  return {
    headers: selectedRegion?.brandUbk
      ? { Brandubk: selectedRegion.brandUbk }
      : {},
  };
};
