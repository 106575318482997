import { Badge, BadgeProps } from '@leagueplatform/web-common-components';
import {
  HeadingText,
  queryHelpers,
  StackLayout,
  useMediaQuery,
} from '@leagueplatform/genesis-core';
import { CoverImage } from '@leagueplatform/genesis-commons';
import React from 'react';

export interface ProgramHeadingProps {
  image?: string;
  imageAltText?: string;
  title: string;
  badge?: BadgeProps | undefined;
  children?: React.ReactNode;
}

const PROGRAM_HEADING_FIXED_HEIGHT = { _: 233, phone: 377, tablet: 437 };

export const ProgramHeading = ({
  image,
  imageAltText,
  title,
  badge,
  children,
}: ProgramHeadingProps) => {
  const isMobile = useMediaQuery(queryHelpers.down('mobileLandscape'));
  const centerAlignHeadingItems = isMobile;

  return (
    <StackLayout
      as="header"
      spacing="$one"
      horizontalAlignment="stretch"
      css={{ width: '100%', marginBottom: '$one' }}
    >
      {image && (
        <StackLayout spacing="$none" horizontalAlignment="stretch">
          <CoverImage
            src={image}
            alt={imageAltText ?? ''}
            height={PROGRAM_HEADING_FIXED_HEIGHT}
            borderRadius="none"
            maxWidth="100%"
            backgroundColor="highlight.background.subdued"
            marginBottom="twoAndHalf"
            verticalAlignment="center"
          />
        </StackLayout>
      )}
      <StackLayout
        spacing="$oneAndHalf"
        horizontalAlignment={centerAlignHeadingItems ? 'center' : 'start'}
      >
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        {badge && <Badge {...badge} />}

        <StackLayout
          css={{
            width: '100%',
            textAlign: centerAlignHeadingItems ? 'center' : 'left',
          }}
          orientation="horizontal"
          horizontalAlignment="spaceBetween"
          verticalAlignment="center"
        >
          <HeadingText level="1" size={{ '@initial': 'xxl', '@mobile': 'xl' }}>
            {title}
          </HeadingText>
        </StackLayout>
        {children}
      </StackLayout>
    </StackLayout>
  );
};
