import {
  RadioButtonGroup,
  FormGroupOptions,
} from '../radio-button-group.component';
import { BACKGROUND_INFORMATION_FORM_GROUPS } from '../background-information-constants';

const preferredWrittenLanguageOptions: FormGroupOptions = [
  {
    label: 'LANGUAGE_ENGLISH',
    value: 'ENGLISH',
  },
  {
    label: 'LANGUAGE_SPANISH',
    value: 'SPANISH',
  },
  {
    label: 'INPUT_OTHER',
    value: 'OTHER (LANGUAGE)',
  },
  {
    label: 'INPUT_DECLINE',
    value: 'CHOOSE NOT TO ANSWER (LANGUAGE)',
  },
];

type PreferredWrittenLanguageGroupProps = {
  pin: string;
  children: React.ReactNode;
};

export const PreferredWrittenLanguageGroup = ({
  pin,
  children,
}: PreferredWrittenLanguageGroupProps) => {
  const name = `${pin}.${BACKGROUND_INFORMATION_FORM_GROUPS.LANGUAGE_WRITTEN}`;

  return (
    <RadioButtonGroup
      key={name}
      name={name}
      legend="LANGUAGE_WRITTEN_LEGEND"
      radioBoxes={preferredWrittenLanguageOptions}
    >
      {children}
    </RadioButtonGroup>
  );
};
