import { RegionSelectionBanner } from '../region-selection-banner.component';

const top = '-66px';

export function RegionSelectionHome() {
  return (
    <RegionSelectionBanner
      css={{
        top,
        '@laptop': { top: `calc(${top} - 22px)` },
        '@desktop': { top: `calc(${top} - 22px)` },
      }}
    />
  );
}
