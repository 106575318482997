import { useIntl } from '@leagueplatform/locales';
import { FormGroupOptions } from '../radio-button-group.component';
import { CheckboxGroup } from '../checkbox-group.component';
import { BACKGROUND_INFORMATION_FORM_GROUPS } from '../background-information-constants';

export const raceOptions: FormGroupOptions = [
  { label: 'RACE_NATIVE', value: 'AMERICAN INDIAN OR ALASKA NATIVE' },
  { label: 'RACE_ASIAN', value: 'ASIAN' },
  { label: 'RACE_BLACK', value: 'BLACK OR AFRICAN AMERICAN' },
  { label: 'RACE_PI', value: 'NATIVE HAWAIIAN' },
  { label: 'RACE_WHITE', value: 'WHITE' },
  { label: 'INPUT_OTHER', value: 'OTHER' },
  { label: 'INPUT_DECLINE', value: 'I CHOOSE NOT TO ANSWER(RACE)' },
];

const CHECKBOX_SELECTION_LIMIT = 2;

type RaceGroupProps = {
  pin: string;
};

export const RaceGroup = ({ pin }: RaceGroupProps) => {
  const { formatMessage } = useIntl();
  const name = `${pin}.${BACKGROUND_INFORMATION_FORM_GROUPS.RACE}`;

  return (
    <CheckboxGroup
      key={name}
      name={name}
      legend="RACE_LEGEND"
      options={raceOptions}
      inputRules={{
        validate: {
          lessThanThree: (v) =>
            !v ||
            v.length <= CHECKBOX_SELECTION_LIMIT ||
            formatMessage({ id: 'INPUT_ERROR_MAX_TWO' }),
        },
      }}
    />
  );
};
