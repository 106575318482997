import {
  RadioButtonGroup,
  FormGroupOptions,
} from '../radio-button-group.component';
import { BACKGROUND_INFORMATION_FORM_GROUPS } from '../background-information-constants';

const preferredSpokenLanguageOptions: FormGroupOptions = [
  {
    label: 'LANGUAGE_ENGLISH',
    value: 'ENGLISH',
  },
  {
    label: 'LANGUAGE_SPANISH',
    value: 'SPANISH',
  },
  {
    label: 'LANGUAGE_ASL',
    value: 'AMERICAN SIGN LANGUAGE',
  },
  {
    label: 'INPUT_OTHER',
    value: 'OTHER (LANGUAGE)',
  },
  {
    label: 'INPUT_DECLINE',
    value: 'CHOOSE NOT TO ANSWER (LANGUAGE)',
  },
];

type PreferredSpokenLanguageGroupProps = {
  pin: string;
};

export const PreferredSpokenLanguageGroup = ({
  pin,
}: PreferredSpokenLanguageGroupProps) => {
  const name = `${pin}.${BACKGROUND_INFORMATION_FORM_GROUPS.LANGUAGE_SPEAKING}`;

  return (
    <RadioButtonGroup
      key={name}
      name={name}
      legend="LANGUAGE_SPEAKING_LEGEND"
      radioBoxes={preferredSpokenLanguageOptions}
    />
  );
};
