import {
  BackgroundInformation,
  MemberAttribute,
  MemberSelection,
} from 'api/get-background-information';
import {
  BackgroundInformationFormData,
  backgroundInformationDefaultValues,
} from 'components/background-information/background-information-constants';
import { raceOptions } from 'components/background-information/form-groups/race-group';
import { isOtherValueField } from './is-other-value-field';

/**
 * Some member attributes might contain an `otherValue`. We should extract this
 * when the attribute `value` loosely matches the `OTHER` key.  There isn't
 * a better way to determine when we should extract this value since the API doesn't
 * currently expose enough metadata.
 */
function findOtherValue(attributes: MemberAttribute[]) {
  const otherValue = attributes.find((attribute) =>
    isOtherValueField(attribute.value),
  )?.otherValue;
  return otherValue;
}

/**
 * 1. The RACE `desc` is unique in the sense where it is the only question/answer that
 * allows multi-select.  We iterate through multiple member attributes and consolidate
 * the values into a single `value` array.
 * 2. There are values that are returned that aren't valid options on the FORM like "FORM LEFT BLANK(RACE)".
 * If we don't filter these out the form sees an option is selected but the UI doesn't show it.
 * Once the categories API is implemented we most likely can remove this.
 */
function parseRace(attribute: DenormalizedMemberAttribute) {
  // Ensure the returned value for race is an array.
  const parsedRace = [attribute.value]
    .flat()
    // Filter out any invalid options.
    .filter((value) => raceOptions.find((option) => option.value === value));
  return parsedRace;
}

type DenormalizedMemberAttribute = {
  value: string | string[];
  otherValue?: string;
};

function denormalizeMemberAttributes(
  attributes: MemberAttribute[],
): DenormalizedMemberAttribute | undefined {
  if (attributes.length === 1) {
    return attributes[0];
  }
  if (attributes.length >= 2) {
    const value = attributes.map((attribute) => attribute.value);
    const otherValue = findOtherValue(attributes);
    return {
      value,
      otherValue,
    };
  }
  return undefined;
}

function denormalizeMemberSelections(selections: MemberSelection[]) {
  const result = selections.reduce((acc, selection) => {
    const denormalizedAttributes = denormalizeMemberAttributes(
      selection.attributes,
    );

    if (selection.desc === 'RACE' && denormalizedAttributes) {
      denormalizedAttributes.value = parseRace(denormalizedAttributes);
    }

    return {
      ...acc,
      [selection.desc]: denormalizedAttributes,
    };
  }, {});

  return result;
}

/**
 * Converts the response payload from the background info API schema into a structure
 * that works with react-hook-form.
 */
export function denormalizeBackgroundInformation(
  backgroundInformation: BackgroundInformation,
): BackgroundInformationFormData {
  const denoramlizedBackgroundInformation =
    backgroundInformation.profileAndPreference.reduce((acc, preferences) => {
      const selections = denormalizeMemberSelections(preferences.selections);

      return {
        ...acc,
        [preferences.pin]: {
          ...backgroundInformationDefaultValues,
          ...selections,
        },
      };
    }, {});

  return denoramlizedBackgroundInformation;
}
