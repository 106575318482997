import { BACKGROUND_INFORMATION_FORM_GROUPS } from '../background-information-constants';
import { INFO_MODAL_TYPES } from '../info-modal-popover.component';
import {
  RadioButtonGroup,
  FormGroupOptions,
} from '../radio-button-group.component';

const genderIdentityRadioBoxes: FormGroupOptions = [
  {
    label: 'GENDER_MALE',
    value: 'MALE',
  },
  {
    label: 'GENDER_FEMALE',
    value: 'FEMALE',
  },
  {
    label: 'GENDER_TRANSGENDER_MALE',
    value: 'TRANSGENDER MTF',
  },
  {
    label: 'GENDER_TRANSGENDER_FEMALE',
    value: 'TRANSGENDER FTM',
  },
  {
    label: 'GENDER_NON_BINARY',
    value: 'GENDERQUEER',
  },
  {
    label: 'INPUT_OTHER',
    value: 'OTHER (GENDER IDENTITY)',
  },
  {
    label: 'INPUT_DECLINE',
    value: 'CHOOSE NOT TO DISCLOSE (GENDER IDENTITY)',
  },
];

export const GenderIdentityGroup = ({ pin }: { pin: string }) => {
  const name = `${pin}.${BACKGROUND_INFORMATION_FORM_GROUPS.GENDER}`;

  return (
    <RadioButtonGroup
      key={name}
      name={name}
      legend="GENDER_LEGEND"
      radioBoxes={genderIdentityRadioBoxes}
      infoModal={INFO_MODAL_TYPES.GENDER_IDENTITY}
    />
  );
};
