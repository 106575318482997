import { BACKGROUND_INFORMATION_FORM_GROUPS } from '../background-information-constants';
import {
  RadioButtonGroup,
  FormGroupOptions,
} from '../radio-button-group.component';

const sexualOrientationRadioBoxes: FormGroupOptions = [
  {
    label: 'ORIENTATION_HETEROSEXUAL',
    value: 'STRAIGHT OR HETEROSEXUAL',
  },
  {
    label: 'ORIENTATION_HOMOSEXUAL',
    value: 'LESBIAN, GAY OR HOMOSEXUAL',
  },
  {
    label: 'ORIENTATION_BISEXUAL',
    value: 'BISEXUAL',
  },
  {
    label: 'INPUT_OTHER',
    value: 'OTHER (SEXUAL ORIENTATION)',
  },
  {
    label: 'INPUT_UNSURE',
    value: "DON'T KNOW (SEXUAL ORIENTATION)",
  },
  {
    label: 'INPUT_DECLINE',
    value: 'CHOOSE NOT TO DISCLOSE (SEXUAL ORIENTATION)',
  },
];

export const SexualOrientationGroup = ({ pin }: { pin: string }) => {
  const name = `${pin}.${BACKGROUND_INFORMATION_FORM_GROUPS.SEXUAL_ORIENTATION}`;

  return (
    <RadioButtonGroup
      key={name}
      name={name}
      legend="ORIENTATION_LEGEND"
      radioBoxes={sexualOrientationRadioBoxes}
    />
  );
};
