import React from 'react';
import { InputStatusMessage } from '@leagueplatform/genesis-core';
import { useFormContext } from '@leagueplatform/web-common';
import { useIntl } from '@leagueplatform/locales';

export const FormErrorStatus = () => {
  const { formatMessage } = useIntl();
  const { formState } = useFormContext();
  const { errors } = formState;

  return Object.keys(errors).length ? (
    <InputStatusMessage inputStatus="error" statusIconLabel="error">
      {formatMessage({ id: 'INPUT_ERROR_FORM' })}
    </InputStatusMessage>
  ) : null;
};
