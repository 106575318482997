import { RegionSelectionBanner } from '../region-selection-banner.component';
import { RegionSelectionStatusBanner } from '../region-selection-status-banner.component';

const maximumWidth = 1280;

export function RegionSelectionWallet() {
  return (
    <>
      <RegionSelectionBanner containerCss={{ marginBottom: '$five' }} />
      <RegionSelectionStatusBanner
        css={{
          maxWidth: maximumWidth,
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      />
    </>
  );
}
