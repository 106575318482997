import React from 'react';
import {
  EVENT_NAME,
  EventProps,
  trackAnalyticsEvent,
} from '@leagueplatform/analytics';
import { useConfigProperty } from '@leagueplatform/core';
import { Button } from '@leagueplatform/genesis-core';
import { Link } from '@leagueplatform/routing';
import { isAbsoluteUrl } from '@leagueplatform/web-common';

export type MasonryButtonSecondaryProps = {
  buttonText: string;
  linkUrl: string;
  analyticsProps?: EventProps;
};

export const MasonryButtonSecondary = ({
  buttonText,
  linkUrl,
  analyticsProps,
}: MasonryButtonSecondaryProps) => {
  const handleLink = useConfigProperty('core.customMethods.handleLink');
  const isAbsoluteLink = isAbsoluteUrl(linkUrl);
  return (
    <Button
      as={isAbsoluteLink ? 'a' : Link}
      href={linkUrl}
      // @ts-ignore-next-line
      to={isAbsoluteLink ? undefined : linkUrl}
      onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
        trackAnalyticsEvent(EVENT_NAME.BUTTON_CLICKED, {
          ...analyticsProps,
          detail: buttonText,
        });
        if (isAbsoluteLink) {
          event.preventDefault();
          handleLink(linkUrl);
        }
      }}
      priority="secondary"
      css={{
        width: 'fit-content',
        marginBlock: '$one',
        '@mobile': {
          fontSize: '$bodyTwo',
          alignSelf: 'center',
        },
      }}
    >
      {buttonText}
    </Button>
  );
};
