import React, { useState } from 'react';
import { HeadingText, Modal } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { RegionSelectionForm } from 'components/region-selection/region-selection-form.component';

export const RegionSelectionModal = ({ onClose }: { onClose: () => void }) => {
  const { formatMessage } = useIntl();
  const [modalOpen, setModalOpen] = useState(true);

  const handleFormClose = () => {
    setModalOpen(false);
    onClose();
  };

  return (
    <Modal.Root
      open={modalOpen}
      onOpenChange={(open) => {
        setModalOpen(open);
        if (!open) {
          onClose();
        }
      }}
    >
      <Modal.Content
        showCloseButton={false}
        css={{
          '.GDS-modal': { width: '381px' },
        }}
      >
        <Modal.Title>{formatMessage({ id: 'SELECT_PLAN' })}</Modal.Title>
        <HeadingText
          level="1"
          size="xl"
          css={{ paddingY: '$oneAndHalf', textAlign: 'center' }}
        >
          {formatMessage({ id: 'SELECT_PLAN' })}
        </HeadingText>
        <RegionSelectionForm onClose={handleFormClose} />
      </Modal.Content>
    </Modal.Root>
  );
};
