import {
  BackgroundInformationFormField,
  BackgroundInformationMultiOptionFormField,
} from 'components/background-information/background-information-constants';

/**
 * Type narrowing function to determine if the field has a single or multiple options.
 */
export function isMultiOptionAttribute(
  field: BackgroundInformationFormField,
): field is BackgroundInformationMultiOptionFormField {
  return Array.isArray(field.value);
}
