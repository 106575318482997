import { Text } from '@leagueplatform/genesis-commons';
import React from 'react';

export const usePrivacyListContent = () => {
  /* Intro section - list content */

  const introLi1 = (
    <>
      <Text>
        When we talk about our “consumer platforms” we mean our enterprise
        websites, mobile applications, social media tools, member and patient
        portals, and our other affiliated online or digital resources that link
        to this Online Privacy Policy. Users are also advised that some of our
        consumer platforms may have separate terms of use/service; users are
        encouraged to review applicable terms of use/service in addition to this
        Online Privacy Policy.
      </Text>
      <Text fontWeight="bold" marginTop="one">
        Note on HIPAA and Protected Health Information
      </Text>
      <Text>
        The Health Insurance Portability and Accountability Act (HIPAA) defines
        how protected health information (PHI) can be used and disclosed. HIPAA
        requires covered entities to maintain a Notice of Privacy Practices
        (NPP), which describes how PHI is collected, used, and shared by the
        entity controlling the platform where the data collection occurred.
        While PHI can be part of the larger category of personal information,
        because PHI is regulated by HIPAA, the terms of an NPP will apply to its
        collection, use, and disclosure rather than this Online Privacy Policy.
        For example, even though our member and patient portals are included in
        our consumer platforms, the information collected on these secure
        portals post- password is subject to HIPAA and an applicable NPP.
      </Text>
    </>
  );

  const introLi2 = [
    'Reference to "users" means any individual visiting, using, and/or providing personal information via one of our consumer platforms. In short, "users" equals "you" when you are visiting or using any of our consumer platforms.',
    'Similarly, references to "your" in this Online Privacy Policy means individual users. You = your = users.',
    'The term "personal information" means any individually identifiable information about a user -- this includes, for example, demographic information such as your name or date of birth; contact information such as address, phone number, or email address; customer-related information such as account number or other identifier; financial information such as payment card or account number for online payments; and digital presence information such as internet protocol (IP) address, click streams (your clicking activity on a page or site), or cookie ID.',
    'When we talk about "third party/third parties" we generally refer to non-affiliated companies whose platforms or components we may employ or present to our users, but whose data collection and usage activities we do not control, and which are not governed by the Plan’s Online Privacy Policy. It can also refer to other types of entities or bodies that we do not have a contractual or commercial relationship with. We’ll provide some examples of third parties below.',
  ];

  /* Data collection section - list content */

  const cookiesLi = [
    'Strictly necessary: cookies which enable various underlying resource features and functionalities such as authenticating users.',
    'Functional: cookies which support enhanced browsing experience and personalization.',
    'Performance/Analytics: cookies which help us evaluate the effectiveness of digital resources, understand user patterns, and measure errors.',
    'Targeting/Advertising: cookies which help us learn your tendencies and develop a profile to serve you relevant content and ads we deem of interest.',
  ];

  /* Personal information section - list content */

  const personalInfoIntroLi = [
    'Provide relevant and tailored health care messaging.',
    'Administer clinical care and insurance benefits and provide information regarding general well-being.',
    'Provide product, program, and service updates, event notices, details about new offerings, and announcements of interest.',
    'Update and maintain information about users.',
    'Monitor the effectiveness of our consumer platforms and features.',
    'Ensure our digital resources function as intended and meet our users’ expectations.',
    'Help us authenticate you as an authorized user and unique individual.',
    'Evaluate your individual experience across our digital properties and help us assess and optimize our products, programs, services, and digital offerings.',
    'Carry out our marketing, advertising, and general commercial business purposes.',
  ];

  const personalInfoDisclosureLi = [
    'Comply with legal obligations.',
    'Comply with legal process and to respond to claims asserted against The Plan.',
    'Respond to verified requests in relation to a criminal investigation or alleged or suspected illegal activity, or any other activity that may expose us or any of our users to legal liability.',
    'Enforce and administer this Online Privacy Policy or any applicable terms of use/service.',
    'Protect the rights of The Plan, its employees, customers, or the public.',
  ];

  return {
    intro: {
      li1: introLi1,
      li2: introLi2,
    },
    dataCollection: { cookiesLi },
    personalInfo: {
      intro: personalInfoIntroLi,
      disclosure: personalInfoDisclosureLi,
    },
  };
};
