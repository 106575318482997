import { BACKGROUND_INFORMATION_FORM_GROUPS } from '../background-information-constants';
import {
  RadioButtonGroup,
  FormGroupOptions,
} from '../radio-button-group.component';

const englishSpeakingLevelRadioBoxes: FormGroupOptions = [
  {
    label: 'SPEAK_ENGLISH_HIGH',
    value: 'VERY WELL',
  },
  {
    label: 'SPEAK_ENGLISH_MEDIUM',
    value: 'WELL',
  },
  {
    label: 'SPEAK_ENGLISH_LOW',
    value: 'NOT WELL',
  },
  {
    label: 'SPEAK_ENGLISH_NONE',
    value: 'NOT AT ALL',
  },
  {
    label: 'INPUT_DECLINE',
    value: 'CHOOSE NOT TO ANSWER (ENGLISH PROFICIENCY)',
  },
];

type EnglishSpeakingLevelGroupProps = {
  pin: string;
};

export const EnglishSpeakingLevelGroup = ({
  pin,
}: EnglishSpeakingLevelGroupProps) => {
  const name = `${pin}.${BACKGROUND_INFORMATION_FORM_GROUPS.ENGLISH_SPEAKING_LEVEL}`;

  return (
    <RadioButtonGroup
      key={name}
      name={name}
      legend="SPEAK_ENGLISH_LEGEND"
      radioBoxes={englishSpeakingLevelRadioBoxes}
    />
  );
};
