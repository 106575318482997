import beneficityLogo from 'assets/beneficity-logo.svg';
import { useIntl } from '@leagueplatform/locales';
import React from 'react';
import { FooterLinkItems, LandingPage } from '@highmark-web/highmark-common';
import { useFooterLinks } from '../hooks/use-footer-links';

export const BrandedLandingPage = () => {
  const { formatMessage } = useIntl();
  const landingGreeting = formatMessage({ id: 'GREETINGS_SUBHEADING' });
  const buttonText = formatMessage({ id: 'CONTINUE_TO_APP' });
  const authErrorText = formatMessage({ id: 'ERROR_CAPTION_BACKEND' });
  const { footerLinks } = useFooterLinks();

  return (
    <LandingPage
      logo={beneficityLogo}
      landingGreeting={landingGreeting}
      buttonText={buttonText}
      authErrorText={authErrorText}
      footer={<FooterLinkItems linkItems={footerLinks} />}
    />
  );
};
