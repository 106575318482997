import { FormField, Select } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { useFormContext } from '@leagueplatform/web-common';
import { DemographicsProfile } from 'api/get-demographics-profile';

type MemberSelectProps = {
  profiles: DemographicsProfile[];
  value: string;
  onChange: (pin: string) => void;
};

export function MemberSelect({ profiles, value, onChange }: MemberSelectProps) {
  const { formatMessage } = useIntl();
  const { formState } = useFormContext();

  return (
    <FormField
      name="member-select"
      id="member-select"
      label={formatMessage({ id: 'BACKGROUND_INFORMATION_SELECT' })}
    >
      <Select
        css={{
          marginBottom: '$two',
          width: '70%',
          minWidth: '200px',
          maxWidth: '350px',
        }}
        options={profiles.map(({ pin, firstName, lastName }) => ({
          key: pin,
          label: `${firstName} ${lastName}`,
          value: pin,
        }))}
        value={value}
        onChange={(event) => {
          onChange(event.currentTarget.value);
        }}
        disabled={!formState.isValid || profiles.length < 2}
      />
    </FormField>
  );
}
