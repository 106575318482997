import { RegionSelectionBanner } from '../region-selection-banner.component';

export function RegionSelectionClaims() {
  return (
    <RegionSelectionBanner
      containerCss={{
        '@mobile': { marginBottom: '$two' },
      }}
      css={{
        '@mobile': {
          top: '-$one',
          paddingX: '$one',
        },
        '@mobileLandscape': { top: '-$three' },
        '@tablet': { top: '-$three' },
        '@laptop': { top: '-$three' },
        '@desktop': { top: '-$three' },
      }}
    />
  );
}
