import { INFO_MODAL_TYPES } from '../info-modal-popover.component';
import {
  RadioButtonGroup,
  FormGroupOptions,
} from '../radio-button-group.component';
import { BACKGROUND_INFORMATION_FORM_GROUPS } from '../background-information-constants';

const ethnicityRadioBoxes: FormGroupOptions = [
  {
    label: 'ETHNICITY_LATINO',
    value: 'HISPANIC OR LATINO/A',
  },
  {
    label: 'ETHNICITY_NON_LATINO',
    value: 'NOT OF HISPANIC, LATINO/A OR SPANISH ORIGIN',
  },
  {
    label: 'INPUT_UNKNOWN',
    value: 'UNKNOWN OR FORM LEFT BLANK(ETHNICITY)',
  },
  {
    label: 'INPUT_DECLINE',
    value: 'I CHOOSE NOT TO ANSWER(ETHNICITY)',
  },
];

type EthnicGroupProps = {
  pin: string;
};

export const EthnicGroup = ({ pin }: EthnicGroupProps) => {
  const name = `${pin}.${BACKGROUND_INFORMATION_FORM_GROUPS.ETHNICITY}`;

  return (
    <RadioButtonGroup
      key={name}
      name={name}
      legend="ETHNICITY_LEGEND"
      radioBoxes={ethnicityRadioBoxes}
      infoModal={INFO_MODAL_TYPES.ETHNICITY}
    />
  );
};
