import {
  RadioButtonGroup,
  FormGroupOptions,
} from '../radio-button-group.component';
import { BACKGROUND_INFORMATION_FORM_GROUPS } from '../background-information-constants';

const languageSpokenHomeOptions: FormGroupOptions = [
  {
    label: 'LANGUAGE_ENGLISH',
    value: 'ENGLISH',
  },
  {
    label: 'LANGUAGE_SPANISH',
    value: 'SPANISH',
  },
  {
    label: 'LANGUAGE_ASL',
    value: 'AMERICAN SIGN LANGUAGE',
  },
  {
    label: 'LANGUAGE_OTHER_PRIMARY',
    value: 'OTHER',
  },
  {
    label: 'INPUT_DECLINE',
    value: 'CHOOSE NOT TO ANSWER',
  },
];

type SpokenLanguageHomeGroupProps = {
  pin: string;
};

export const SpokenLanguageHomeGroup = ({
  pin,
}: SpokenLanguageHomeGroupProps) => {
  const name = `${pin}.${BACKGROUND_INFORMATION_FORM_GROUPS.LANGUAGE_HOME}`;

  return (
    <RadioButtonGroup
      key={name}
      name={name}
      legend="LANGUAGE_HOME_LEGEND"
      radioBoxes={languageSpokenHomeOptions}
    />
  );
};
