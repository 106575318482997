import { CurrentUbkHeader } from 'hooks/use-current-region-ubk-header.hook';
import { highmarkFetch } from '../utils/highmark-fetch';
import { HsaRegistrationData } from '../hooks/use-get-hsa-registration-eligibility.hook';

export const HSA_ENROLLMENT_API = '/v1/members/spending/hsa/enroll';

export const postHsaEnrollment = async (
  partnerAccessToken: string,
  hsaEligibility: HsaRegistrationData,
  currentUbkHeader: CurrentUbkHeader,
) => {
  const payload = {
    memberId: hsaEligibility.memberId,
    groupNumber: hsaEligibility.groupNumber,
    clientNumber: hsaEligibility.clientNumber,
  };

  const response = await highmarkFetch(HSA_ENROLLMENT_API, partnerAccessToken, {
    method: 'POST',
    body: JSON.stringify(payload),
    ...currentUbkHeader,
  });

  return response.json();
};
