import React from 'react';
import { RegionUbk } from 'api/get-app-init-details';

export interface RegionSelection {
  selectedRegion: RegionUbk | undefined;
  regionOptions?: RegionUbk[] | undefined;
}

export const RegionSelectionContext = React.createContext<RegionSelection>({
  selectedRegion: {
    brandUbk: undefined,
    clientNumber: undefined,
    groupNumber: undefined,
    planName: undefined,
    planType: undefined,
  },
  regionOptions: undefined,
});
