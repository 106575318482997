export enum BACKGROUND_INFORMATION_FORM_GROUPS {
  EDUCATION = 'EDUCATION COMPLETION LEVEL',
  ENGLISH_SPEAKING_LEVEL = 'SPOKEN ENGLISH LANGUAGE PROFICIENCY',
  ETHNICITY = 'ETHNICITY',
  GENDER = 'GENDER IDENTITY',
  LANGUAGE_HOME = 'LANGUAGE SPOKEN AT HOME',
  LANGUAGE_SPEAKING = 'PREFERRED SPOKEN LANGUAGE',
  LANGUAGE_WRITTEN = 'PREFERRED WRITTEN LANGUAGE',
  PRONOUNS = 'PRONOUNS',
  RACE = 'RACE',
  SEX_AT_BIRTH = 'SEX ASSIGNED AT BIRTH',
  SEXUAL_ORIENTATION = 'SEXUAL ORIENTATION',
}

export type BackgroundInformationSingleOptionFormField = {
  value: string;
  otherValue?: string;
};

export type BackgroundInformationMultiOptionFormField = {
  value: string[];
  otherValue?: string;
};

export type BackgroundInformationFormField =
  | BackgroundInformationSingleOptionFormField
  | BackgroundInformationMultiOptionFormField;

export const backgroundInformationDefaultValues: Record<
  BACKGROUND_INFORMATION_FORM_GROUPS,
  BackgroundInformationFormField
> = {
  [BACKGROUND_INFORMATION_FORM_GROUPS.EDUCATION]: { value: '' },
  [BACKGROUND_INFORMATION_FORM_GROUPS.ENGLISH_SPEAKING_LEVEL]: { value: '' },
  [BACKGROUND_INFORMATION_FORM_GROUPS.ETHNICITY]: { value: '' },
  [BACKGROUND_INFORMATION_FORM_GROUPS.GENDER]: { value: '' },
  [BACKGROUND_INFORMATION_FORM_GROUPS.LANGUAGE_HOME]: { value: '' },
  [BACKGROUND_INFORMATION_FORM_GROUPS.PRONOUNS]: { value: '' },
  [BACKGROUND_INFORMATION_FORM_GROUPS.RACE]: { value: [] },
  [BACKGROUND_INFORMATION_FORM_GROUPS.SEX_AT_BIRTH]: { value: '' },
  [BACKGROUND_INFORMATION_FORM_GROUPS.SEXUAL_ORIENTATION]: { value: '' },
  [BACKGROUND_INFORMATION_FORM_GROUPS.LANGUAGE_SPEAKING]: { value: '' },
  [BACKGROUND_INFORMATION_FORM_GROUPS.LANGUAGE_WRITTEN]: { value: '' },
};

export type BackgroundInformationOptions =
  typeof backgroundInformationDefaultValues;

export type BackgroundInformationFormData = Record<
  string,
  BackgroundInformationOptions
>;
