import React from 'react';
import {
  Box,
  StackLayout,
  queryHelpers,
  useMediaQuery,
} from '@leagueplatform/genesis-core';
import { Route, Switch, useRouteMatch } from '@leagueplatform/routing';
import { APP_MANAGED_ROUTES } from 'common/app-managed-routes';
import { MasonryPageContainer } from 'pages/masonry/masonry-container.page';
import { LegalSidebar } from './legal-sidebar/legal-sidebar.component';
import { LegalContent } from './legal-content-container/legal-content.component';

type LegalPageContainerProps = {
  children: React.ReactNode;
};

function LegalPageContainer({ children }: LegalPageContainerProps) {
  return (
    <Box css={{ overflowX: 'hidden', position: 'relative' }}>{children}</Box>
  );
}

export const LegalPage = () => {
  const isMobile = useMediaQuery(queryHelpers.down('tablet'));
  const { path } = useRouteMatch();

  if (isMobile) {
    return (
      <MasonryPageContainer>
        <Switch>
          <Route path={path} exact>
            <LegalSidebar />
          </Route>
          <Route path={`${path}${APP_MANAGED_ROUTES.LEGAL_DETAILS_SUBPATH}`}>
            <LegalContent />
          </Route>
        </Switch>
      </MasonryPageContainer>
    );
  }

  return (
    <LegalPageContainer>
      <MasonryPageContainer>
        <StackLayout
          orientation="horizontal"
          spacing={{ '@desktop': '$two' }}
          horizontalAlignment="spaceBetween"
        >
          <LegalSidebar />
          <Route path={`${path}${APP_MANAGED_ROUTES.LEGAL_DETAILS_SUBPATH}`}>
            <LegalContent />
          </Route>
        </StackLayout>
      </MasonryPageContainer>
    </LegalPageContainer>
  );
};
