import { forwardRef } from 'react';
import { RadioRowInput } from 'components/background-information/radio-row-input.component';
import { useIntl } from '@leagueplatform/locales';
import { useFormContext } from '@leagueplatform/web-common';
import { InputField, InputFieldProps } from './input-field.component';

type OtherRadioButtonProps = {
  name: string;
  value: string;
  inputProps: InputFieldProps;
};

export const OtherRadioButton = forwardRef<
  HTMLInputElement,
  OtherRadioButtonProps
>(({ name, value, inputProps, ...props }, ref) => {
  const { formatMessage } = useIntl();
  const inputErrorMessage = formatMessage({ id: 'INPUT_ERROR_REQUIRED' });
  const { watch } = useFormContext();
  const selectedValue = watch(name);
  const isOtherSelected = selectedValue === value;

  return (
    <>
      <RadioRowInput
        id={`${name}-${value}`}
        label={isOtherSelected ? 'INPUT_OTHER_REQUIRED' : 'INPUT_OTHER'}
        name={name}
        value={value}
        ref={ref}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
      {isOtherSelected ? (
        <InputField
          inputOptions={{
            required: {
              value: true,
              message: inputErrorMessage,
            },
          }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...inputProps}
        />
      ) : null}
    </>
  );
});
