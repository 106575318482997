import React from 'react';
import {
  AuthedWebviewModal,
  FullScreenContainer,
  LinkHandler,
  LoadingSpinner,
  WalkthroughLayout,
} from '@highmark-web/highmark-common';
import { Box } from '@leagueplatform/genesis-commons';
import account from 'assets/walkthrough-slides/complete-account.png';
import logo from 'assets/beneficity-logo.svg';
import { useGetWalkthroughSlideContent } from './use-get-walkthrough-slide-content.hook';

export const WalkthroughContainer = ({
  onCompleteWalkthrough,
}: {
  onCompleteWalkthrough: () => void;
}) => {
  const { slideData, brandingIsLoading } = useGetWalkthroughSlideContent();

  return (
    <FullScreenContainer backgroundColor="surface.background.primary">
      {brandingIsLoading ? (
        <LoadingSpinner />
      ) : (
        <Box>
          <WalkthroughLayout
            slideData={slideData}
            numberOfSlides={7}
            logo={logo}
            formImage={account}
            hasBrandSlide
            onCompleteWalkthrough={onCompleteWalkthrough}
          />
        </Box>
      )}
      <AuthedWebviewModal linkHandler={LinkHandler} />
    </FullScreenContainer>
  );
};
