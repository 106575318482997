import { Box, Button, VisuallyHidden } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { useLiveChatCta } from '@leagueplatform/live-chat';
import MessagesBubbleTypingIcon from 'assets/icons/messages-bubble-typing.svg';
import { UnreadMessageIndicator } from './live-chat-unread-message-indicator';

export function LiveChatCTA() {
  const { formatMessage } = useIntl();
  const { openChat, unreadMessageCount, liveChatButtonRef } = useLiveChatCta();

  return (
    <Box
      css={{
        position: 'fixed',
        bottom: '1rem',
        right: '1rem',
      }}
    >
      <UnreadMessageIndicator unreadMessageCount={unreadMessageCount}>
        <Button
          icon={MessagesBubbleTypingIcon}
          hideLabel
          priority="primary"
          onClick={openChat}
          ref={liveChatButtonRef}
          css={{
            '.GDS-icon': { width: '3rem', height: '3rem', margin: '0.5rem' },
          }}
          aria-live="polite"
        >
          <span aria-hidden>{formatMessage({ id: 'LIVE_CHAT' })}</span>
          {unreadMessageCount > 0 ? (
            <VisuallyHidden>
              {formatMessage(
                { id: 'LIVE_CHAT_UNREAD_MESSAGES' },
                { count: unreadMessageCount },
              )}
            </VisuallyHidden>
          ) : (
            formatMessage({ id: 'LIVE_CHAT' })
          )}
        </Button>
      </UnreadMessageIndicator>
    </Box>
  );
}
