import { forwardRef } from 'react';
import { GDSStyleObject, Checkbox } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';

export type CheckboxRowInputProps = {
  id: string;
  name: string;
  label: string;
  value?: string;
  disabled?: boolean;
  css?: GDSStyleObject;
};

export const CheckboxRowInput = forwardRef<
  HTMLInputElement,
  CheckboxRowInputProps
  // eslint-disable-next-line react/prop-types
>(({ id, name, label, value, disabled, css = {}, ...props }, ref) => {
  const { formatMessage } = useIntl();

  return (
    <Checkbox
      ref={ref}
      id={id}
      name={name}
      value={value || undefined}
      label={formatMessage({ id: label })}
      layout="row"
      css={{ '.GDS-checkbox-label-text': { fontSize: '1.4rem' }, ...css }}
      disabled={disabled}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
});
