import { forwardRef } from 'react';
import {
  BaseInput as GenesisBaseInput,
  InputLabel,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';

export type BaseInputProps = {
  id: string;
  name: string;
  label: string;
  placeholder: string;
};

export const BaseInput = forwardRef<HTMLInputElement, BaseInputProps>(
  // eslint-disable-next-line react/prop-types
  ({ id, name, label, placeholder, ...props }, ref) => {
    const { formatMessage } = useIntl();

    return (
      <>
        <InputLabel hideLabel htmlFor={id}>
          {formatMessage({ id: label })}
        </InputLabel>
        <GenesisBaseInput
          ref={ref}
          id={id}
          name={name}
          placeholder={formatMessage({ id: placeholder })}
          css={{
            width: '70%',
            minWidth: '300px',
            '.GDS-input-status-message-wrapper': { marginTop: '0px' },
          }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      </>
    );
  },
);
