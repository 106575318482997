import { highmarkFetch } from 'utils/highmark-fetch';
import { CurrentUbkHeader } from 'hooks/use-current-region-ubk-header.hook';

const DEMOGRAPHICS_PROFILE_URL = '/v1/members/demographics/profile';

export async function getDemographicsProfile(
  partnerAccessToken: string,
  currentUbkHeader: CurrentUbkHeader,
) {
  const demographicsProfile: DemographicsProfileSchema = await highmarkFetch(
    DEMOGRAPHICS_PROFILE_URL,
    partnerAccessToken,
    { ...currentUbkHeader },
  ).then((response) => response.json());

  return demographicsProfile;
}

export type DemographicsProfile = {
  firstName: string;
  lastName: string;
  pin: string;
  dateOfBirth?: string;
};

type DemographicsProfileSchema = {
  demographics: {
    firstName: string;
    lastName: string;
    dateOfBirth?: string;
    pin: string;
    dependents?: DemographicsProfile[];
  };
};
