import {
  BackgroundInfoSchema,
  MemberAttribute,
} from 'api/get-background-information';
import {
  BackgroundInformationFormData,
  BackgroundInformationFormField,
  BackgroundInformationMultiOptionFormField,
  BackgroundInformationOptions,
  BackgroundInformationSingleOptionFormField,
} from 'components/background-information/background-information-constants';
import { isOtherValueField } from './is-other-value-field';
import { isMultiOptionAttribute } from './is-multi-option-attribute';

function prepareRaceDefaultValues(field: BackgroundInformationFormField) {
  const attributes: MemberAttribute[] = [];

  // No race was selected.
  if (field.value.length === 0) {
    attributes.push({
      value: 'FORM LEFT BLANK(RACE)',
    });
  }
  // A second race wasn't selected.
  if (field.value.length <= 1) {
    attributes.push({
      value: 'NO 2ND RACE SELECTED',
    });
  }

  return attributes;
}

function normalizeField(field: BackgroundInformationSingleOptionFormField) {
  return {
    value: field.value,
    otherValue: isOtherValueField(field.value) ? field.otherValue : undefined,
  };
}

function normalizeMultiOptionField(
  field: BackgroundInformationMultiOptionFormField,
) {
  const attributes = field.value.map((value) =>
    normalizeField({ value, otherValue: field.otherValue }),
  );
  return attributes;
}

function normalizeFormField(
  desc: string,
  field: BackgroundInformationFormField,
) {
  const attributes: MemberAttribute[] = [];

  if (isMultiOptionAttribute(field)) {
    if (desc === 'RACE') {
      attributes.push(...prepareRaceDefaultValues(field));
    }
    attributes.push(...normalizeMultiOptionField(field));
  } else if (typeof field.value === 'string' && field.value !== '') {
    attributes.push(normalizeField(field));
  }

  return attributes;
}

function normalizeMemberSelections(fields: BackgroundInformationOptions) {
  const memberSelections = Object.entries(fields)
    .map(([desc, values]) => ({
      desc,
      attributes: normalizeFormField(desc, values),
    }))
    // Remove any selections that don't have any attributes.
    .filter((selection) => selection.attributes.length > 0);

  return memberSelections;
}

function normalizeMembers(
  backgroundInformation: BackgroundInformationFormData,
) {
  const profileAndPreferences = Object.entries(backgroundInformation)
    .map(([pin, fields]) => ({
      pin,
      selections: normalizeMemberSelections(fields),
    }))
    // Remove any members that haven't made selections.
    .filter((member) => member.selections.length > 0);

  return profileAndPreferences;
}

/**
 * Convert back the results provided by react-hook-form into the background info schema.
 */
export function normalizeBackgroundInformation({
  memberId,
  backgroundInformation,
}: {
  memberId: string;
  backgroundInformation: BackgroundInformationFormData;
}) {
  const normalizedBackgroundInformation: BackgroundInfoSchema = {
    backgroundInfo: {
      memberId,
      profileAndPreference: normalizeMembers(backgroundInformation),
    },
  };

  return normalizedBackgroundInformation;
}
