import { forwardRef } from 'react';
import { useIntl } from '@leagueplatform/locales';
import { useFormContext } from '@leagueplatform/web-common';
import { InputField, InputFieldProps } from './input-field.component';
import { CheckboxRowInput } from './checkbox-row-input.component';

type OtherCheckboxButtonProps = {
  name: string;
  value: string;
  disabled?: boolean;
  inputProps: InputFieldProps;
};

export const OtherCheckboxButton = forwardRef<
  HTMLInputElement,
  OtherCheckboxButtonProps
>(({ name, disabled, value, inputProps, ...props }, ref) => {
  const { formatMessage } = useIntl();
  const inputErrorMessage = formatMessage({ id: 'INPUT_ERROR_REQUIRED' });
  const { watch } = useFormContext();
  const selectedValue = watch(name, []);
  const isOtherSelected = selectedValue.includes(value);

  return (
    <>
      <CheckboxRowInput
        id={`${name}-${value}`}
        label={isOtherSelected ? 'INPUT_OTHER_REQUIRED' : 'INPUT_OTHER'}
        name={name}
        value={value}
        ref={ref}
        disabled={disabled}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
      {isOtherSelected ? (
        <InputField
          inputOptions={{
            required: {
              value: true,
              message: inputErrorMessage,
            },
          }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...inputProps}
        />
      ) : null}
    </>
  );
});
