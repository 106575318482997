import React from 'react';
import { SkeletonBox } from '@leagueplatform/web-common-components';
import {
  ContainerWidgetItem,
  useGetWidgetData,
} from '@leagueplatform/masonry-api';
import { MASONRY } from '../../common/constants';

/**
 * This component is used to wrap a Masonry 1.5/2 component to work with Masonry 1.0 as a custom component.
 */
export const MasonryV1WidgetWrapper = ({
  widget,
  component: Widget,
}: {
  widget: ContainerWidgetItem;
  component: React.ComponentType<any>;
}) => {
  const {
    data: widgetData,
    isLoading,
    error,
  } = useGetWidgetData<any>(widget, MASONRY.API_VERSION);

  if (isLoading) return <SkeletonBox width="100%" height="120px" />;

  if (error || !widgetData || !widgetData.attributes) {
    return null;
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Widget {...widgetData.attributes} />;
};
