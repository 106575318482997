import {
  BACKGROUND_INFORMATION_FORM_GROUPS,
  BackgroundInformationFormData,
} from 'components/background-information/background-information-constants';
import { useState } from 'react';
import { Box, Button, Checkbox, StackItem } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { FormErrorStatus } from 'components/background-information/form-error-status.component';
import { FormProvider, useForm } from '@leagueplatform/web-common';
import { RaceGroup } from 'components/background-information/form-groups/race-group';
import { EthnicGroup } from 'components/background-information/form-groups/ethnic-group';
import { EnglishSpeakingLevelGroup } from 'components/background-information/form-groups/english-speaking-level-group';
import { EducationGroup } from 'components/background-information/form-groups/education-group';
import { SexAtBirthGroup } from 'components/background-information/form-groups/sex-at-birth-group';
import { GenderIdentityGroup } from 'components/background-information/form-groups/gender-identity-group';
import { PronounsGroup } from 'components/background-information/form-groups/pronouns-group';
import { SexualOrientationGroup } from 'components/background-information/form-groups/sexual-orientation-group';
import { DemographicsProfile } from 'api/get-demographics-profile';
import { calculateAge } from 'utils/calculate-age';
import { SpokenLanguageHomeGroup } from './form-groups/spoken-home-language';
import { PreferredWrittenLanguageGroup } from './form-groups/preferred-written-language';
import { PreferredSpokenLanguageGroup } from './form-groups/preferred-spoken-language';
import { MemberSelect } from './member-select.component';

const AUTO_UPDATE_GROUPS = [
  BACKGROUND_INFORMATION_FORM_GROUPS.RACE,
  BACKGROUND_INFORMATION_FORM_GROUPS.ENGLISH_SPEAKING_LEVEL,
  BACKGROUND_INFORMATION_FORM_GROUPS.ETHNICITY,
  BACKGROUND_INFORMATION_FORM_GROUPS.LANGUAGE_SPEAKING,
  BACKGROUND_INFORMATION_FORM_GROUPS.LANGUAGE_WRITTEN,
  BACKGROUND_INFORMATION_FORM_GROUPS.LANGUAGE_HOME,
];

type BackgroundInformationFormProps = {
  profiles: DemographicsProfile[];
  initialValues?: BackgroundInformationFormData;
  onSubmit: (data: BackgroundInformationFormData) => void;
  selectedProfileIndex: number;
  onSelectProfileIndexChange: (profileIndex: number) => void;
};

export const BackgroundInformationForm = ({
  profiles,
  initialValues,
  onSubmit,
  selectedProfileIndex,
  onSelectProfileIndexChange,
}: BackgroundInformationFormProps) => {
  const hideIfNoDependents = profiles.length > 1;
  const { formatMessage } = useIntl();
  const methods = useForm<BackgroundInformationFormData>({
    defaultValues: initialValues,
  });
  const selectedMember = profiles[selectedProfileIndex];
  const isSubscriberSelected = selectedMember.pin === profiles[0].pin;
  const { pin, dateOfBirth } = selectedMember;
  const isMemberAgeOver18 = dateOfBirth
    ? calculateAge(dateOfBirth) >= 18
    : false;
  const [
    isPreferenceSameForAllDependents,
    setIsPreferenceSameForAllDependents,
  ] = useState(false);
  const shouldAutoUpdateDependents =
    isSubscriberSelected && isPreferenceSameForAllDependents;

  return (
    <FormProvider
      formOptions={{
        mode: 'onSubmit',
        defaultValues: initialValues,
        reValidateMode: 'onChange',
      }}
      onSubmit={(values) => {
        // Override the dependent values with the subscribers when the subscriber checks the auto-update box.
        if (shouldAutoUpdateDependents) {
          // Deep clone the values before mutating them.
          const autoUpdatedValues = JSON.parse(JSON.stringify(values));

          AUTO_UPDATE_GROUPS.forEach((name) => {
            const groupValues = values[pin][name];
            profiles
              .filter((p) => p.pin !== pin)
              .forEach((profile) => {
                // eslint-disable-next-line no-param-reassign
                autoUpdatedValues[profile.pin][name] = groupValues;
              });
          });
          return onSubmit(autoUpdatedValues);
        }
        return onSubmit(values);
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...methods}
    >
      <MemberSelect
        profiles={profiles}
        value={pin}
        onChange={(selectedPin) => {
          onSelectProfileIndexChange(
            profiles.findIndex((p) => p.pin === selectedPin),
          );
        }}
      />

      <RaceGroup pin={pin} />
      <EthnicGroup pin={pin} />
      <SpokenLanguageHomeGroup pin={pin} />
      <PreferredSpokenLanguageGroup pin={pin} />
      <EnglishSpeakingLevelGroup pin={pin} />
      <PreferredWrittenLanguageGroup pin={pin}>
        {hideIfNoDependents && isSubscriberSelected ? (
          <Checkbox
            id="preferences-same-for-all-dependents"
            name="preferences-same-for-all-dependents"
            label={formatMessage({
              id: 'RACE_ETHNICITY_LANGAUGE_SAME_FOR_ALL_DEPENDENTS',
            })}
            css={{ '.GDS-checkbox-label-text': { fontSize: '1.4rem' } }}
            defaultChecked={isPreferenceSameForAllDependents}
            onChange={(value) => {
              const { checked } = value.currentTarget;
              setIsPreferenceSameForAllDependents(checked);
            }}
          />
        ) : null}
      </PreferredWrittenLanguageGroup>
      {isMemberAgeOver18 ? <EducationGroup pin={pin} /> : null}
      <SexAtBirthGroup pin={pin} />
      <GenderIdentityGroup pin={pin} />
      <PronounsGroup pin={pin} />
      <SexualOrientationGroup pin={pin} />
      <Box
        css={{
          width: '100%',
          height: '1px',
          paddingTop: '$one',
          borderTop: '1px solid $onSurfaceBorderSubdued',
        }}
      />
      <FormErrorStatus />
      <StackItem
        horizontalAlignment="center"
        css={{ marginTop: '$oneAndHalf' }}
      >
        <Button type="submit" priority="primary" size="medium">
          {formatMessage({ id: 'BACKGROUND_INFORMATION_SAVE' })}
        </Button>
      </StackItem>
    </FormProvider>
  );
};
