import { CurrentUbkHeader } from 'hooks/use-current-region-ubk-header.hook';
import { highmarkFetch } from '../utils/highmark-fetch';
import {
  BACKGROUND_INFORMATION_URL,
  BackgroundInfoSchema,
} from './get-background-information';

export async function postBackgroundInformation(
  partnerAccessToken: string,
  backgroundInformation: BackgroundInfoSchema,
  currentUbkHeader: CurrentUbkHeader,
) {
  const response = await highmarkFetch(
    BACKGROUND_INFORMATION_URL,
    partnerAccessToken,
    {
      method: 'PUT',
      body: JSON.stringify(backgroundInformation),
      ...currentUbkHeader,
    },
  );

  if (!response?.ok) {
    throw new Error(
      `Error Response Code ${response?.status}: ${await response.text()}`,
    );
  }

  return response.json();
}
