import { BACKGROUND_INFORMATION_FORM_GROUPS } from '../background-information-constants';
import {
  RadioButtonGroup,
  FormGroupOptions,
} from '../radio-button-group.component';

const sexAtBirthRadioBoxes: FormGroupOptions = [
  {
    label: 'SEX_MALE',
    value: 'MALE',
  },
  {
    label: 'SEX_FEMALE',
    value: 'FEMALE',
  },
  {
    label: 'INPUT_UNKNOWN',
    value: 'UNKNOWN',
  },
  {
    label: 'INPUT_DECLINE',
    value: 'CHOOSE NOT TO DISCLOSE (SEX ASSIGNED AT BIRTH)',
  },
];

export const SexAtBirthGroup = ({ pin }: { pin: string }) => {
  const name = `${pin}.${BACKGROUND_INFORMATION_FORM_GROUPS.SEX_AT_BIRTH}`;

  return (
    <RadioButtonGroup
      key={name}
      name={name}
      legend="SEX_LEGEND"
      radioBoxes={sexAtBirthRadioBoxes}
    />
  );
};
