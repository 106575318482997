import { CurrentUbkHeader } from 'hooks/use-current-region-ubk-header.hook';
import { highmarkFetch } from '../utils/highmark-fetch';

export const BACKGROUND_INFORMATION_URL = '/v1/members/background/information';

export async function getBackgroundInformation(
  partnerAccessToken: string,
  currentUbkHeader: CurrentUbkHeader,
) {
  const response = await highmarkFetch(
    BACKGROUND_INFORMATION_URL,
    partnerAccessToken,
    { ...currentUbkHeader },
  );

  if (!response?.ok) {
    throw new Error(
      `Error Response Code ${response?.status}: ${await response.text()}`,
    );
  }

  return response.json();
}

export type MemberAttribute = {
  value: string;
  otherValue?: string;
};

export type MemberSelection = {
  desc: string;
  attributes: MemberAttribute[];
};

export type ProfileAndPreference = {
  pin: string;
  selections: MemberSelection[];
};

export type BackgroundInformation = {
  memberId: string;
  profileAndPreference: ProfileAndPreference[];
};

export type BackgroundInfoSchema = {
  backgroundInfo: BackgroundInformation;
};
