import {
  ActivityStackWidgetV2,
  HEALTH_JOURNEY_NAMESPACE,
  HEALTH_JOURNEY_NODE_TYPES,
} from '@leagueplatform/health-journey';
import { MasonryWSDriverNodeRenderersRegistry } from '@leagueplatform/masonry';

export const initAppMasonryRegistry = () => {
  MasonryWSDriverNodeRenderersRegistry.overrideRenderer(
    HEALTH_JOURNEY_NAMESPACE,
    HEALTH_JOURNEY_NODE_TYPES.ACTIVITIES_WIDGET,
    ActivityStackWidgetV2,
  );
};
