import React, { memo } from 'react';
import {
  Disclaimer,
  Footer,
  useMobileAppDownloadLinks,
} from '@highmark-web/highmark-common';
import { useIntl } from '@leagueplatform/locales';
import { useFooterLinks } from 'hooks/use-footer-links';
import { APP_STORE_PROD_URL, PLAY_STORE_PROD_URL } from 'common/constants';

export const BeneficityFooter = memo(() => {
  const { formatMessage } = useIntl();
  const { footerLinks } = useFooterLinks();
  const { mobileAppDownloadLinks } = useMobileAppDownloadLinks(
    APP_STORE_PROD_URL,
    PLAY_STORE_PROD_URL,
  );
  // mirror the format returned by the BE for dynamic disclaimers
  const disclaimer = `${formatMessage({
    id: 'DISCLAIMER_BODY_1',
  })}\n${formatMessage({ id: 'DISCLAIMER_BODY_2' })}\n${formatMessage({
    id: 'DISCLAIMER_BODY_3',
  })}\n${formatMessage({ id: 'DISCLAIMER_BODY_4' })}`;

  return (
    <Footer
      disclaimer={<Disclaimer disclaimer={disclaimer} />}
      footerLinks={footerLinks}
      footerAppDownloadAssets={mobileAppDownloadLinks}
    />
  );
});
