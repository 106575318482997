/**
 * This function takes any input string, and formats it in title case
 * @param {string}  str - The string to be formatted in title case.
 * @returns {string} The input string in title case
 */
export const toTitleCase = (str: string = '') =>
  str
    .toLowerCase()
    .split(' ')
    .map((word) => `${word.charAt(0).toUpperCase()}${word.slice(1)}`)
    .join(' ');
