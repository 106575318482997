import {
  FEATURE_FLAGS,
  createLiveChatClient,
} from '@highmark-web/highmark-common';
import { LeagueCombinedConfig } from '@leagueplatform/core';
import { firebaseRemoteConfig } from 'common/firebase';
import { getBoolean } from 'firebase/remote-config';
import { injectLiveChatQualtricsScript } from './inject-live-chat-qualtrics-script';

export function getLiveChatConfig(): LeagueCombinedConfig['liveChat'] {
  return {
    liveChatClient: createLiveChatClient({
      env: import.meta.env.VITE_ENV,
      onChatEnd() {
        injectLiveChatQualtricsScript();
      },
    }),
    shouldShowNavigateAway: getBoolean(
      firebaseRemoteConfig,
      FEATURE_FLAGS.LIVE_CHAT_NAVIGATE_AWAY,
    ),
  };
}
