import { QueryClient, QueryClientProvider } from 'react-query';
import React from 'react';
import {
  FetchAndActivateProps,
  useFetchAndActivate,
} from 'hooks/use-fetch-and-activate.hook';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 3,
    },
  },
});

const FirebaseActivator = ({
  remoteConfig,
  staleTimeOverride,
  cacheTimeOverride,
  defaultValues,
}: FetchAndActivateProps) => {
  useFetchAndActivate({
    remoteConfig,
    staleTimeOverride,
    cacheTimeOverride,
    defaultValues,
  });

  return null;
};

/* Set up a query provider just for the Firebase fetch so we have
 * remote config values ready prior to League Provider being initialized
 * and have them immediately available for SDK configs to pull from
 */
export const FirebaseActivatorContainer = ({
  remoteConfig,
  staleTimeOverride,
  cacheTimeOverride,
  defaultValues,
}: FetchAndActivateProps) => (
  <QueryClientProvider client={queryClient}>
    <FirebaseActivator
      remoteConfig={remoteConfig}
      staleTimeOverride={staleTimeOverride}
      cacheTimeOverride={cacheTimeOverride}
      defaultValues={defaultValues}
    />
  </QueryClientProvider>
);
