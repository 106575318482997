export const BACKGROUND_INFORMATION_LOCALE_STRINGS_EN = {
  /* Main modal content */
  BACKGROUND_INFORMATION: 'Background Information',
  BACKGROUND_INFORMATION_ACCEPT: 'OK',
  BACKGROUND_INFORMATION_BACK: 'Back to Account Management',
  BACKGROUND_INFORMATION_HEADING:
    'Information about your race, ethnicity, language, education, gender identity, and impairment is used to help us better understand and support all of our members and their needs.',
  BACKGROUND_INFORMATION_SAME_INFO:
    'Race, ethnicity, and language information is the same for all dependents.',
  BACKGROUND_INFORMATION_SAVE: 'Save Changes',
  BACKGROUND_INFORMATION_SELECT: 'Select a Member',
  BACKGROUND_INFORMATION_TITLE: 'Background Information',
  BACKGROUND_INFORMATION_TOGGLE: 'Expand/Collapse Section',
  /* Gender Section */
  GENDER_FEMALE: 'Female',
  GENDER_LEGEND: "What is your/the member's gender identity?",
  GENDER_MALE: 'Male',
  GENDER_NON_BINARY: 'Non-Binary',
  GENDER_TRANSGENDER_FEMALE: 'Transgender female',
  GENDER_TRANSGENDER_MALE: 'Transgender male',
  /* Education Section */
  EDUCATION_COLLEGE: '4-year college graduate',
  EDUCATION_COLLEGE_PLUS: 'More than 4-year college degree',
  EDUCATION_ELEMENTARY: '8th grade or less',
  EDUCATION_HS: 'High school graduate or GED',
  EDUCATION_LEGEND:
    'What is the highest grade or level of school you have completed?',
  EDUCATION_SOME_COLLEGE: 'Some college or 2-year degree',
  EDUCATION_SOME_HS: 'Some high school, but did not graduate',
  /* Ethnicity Section */
  ETHNICITY_LATINO: 'Hispanic or Latino',
  ETHNICITY_LEGEND: 'Ethnic Background',
  ETHNICITY_NON_LATINO: 'Not Hispanic or Latino',
  /* Info Popovers */
  INFO_CONTENT_BACKGROUND_INFORMATION: `<span style="display:block;padding-bottom:10px;margin-top:-10px";>Why do we need this information?</span>Here at Highmark, we foster a community that promotes health equity — and we recognize that health inequities must be adequately identified to be addressed. We collect data regarding your race, ethnicity, language (“REL”), sexual orientation and gender identity (“SOGI") in an effort to develop and provide unique services to meet the specific needs of our patients, members, and communities. By voluntarily submitting this data, you will guide us in assessing health care disparities, improving our overall quality of care, and measuring improvement in tackling these issues.
  <span style="display:block;padding-bottom:10px";>How will this information be used?</span>The data collected will only be used for diversity, equity, and inclusion purposes. By knowing your language preferences, we can improve our communication with you. We will never use your data to determine your benefits or coverage, how much you pay for coverage, or for how we pay your claims. When we receive REL-SOGI data from you, we strive to design culturally responsive services and address potential elements of bias when identifying which patients and members would benefit from proactive health care. The REL-SOGI data you provide to us may be shared with your health care practitioners and providers, or as necessary to fulfill Highmark’s regulatory requirements. We understand that some of this information may be fluid and want to ensure that we maintain the most up-to date information in your medical records.
  <span style="display:block;padding-bottom:10px";>Will this information be protected?</span>We are committed to protecting your personal information and handling it with respect and integrity. The information you provide to us in response to these questions will be securely maintained and will only be used by authorized users for the specific purposes outlined above. Any access by such authorized users will be limited to what is minimally necessary to carry out their responsibilities. Authorized users must complete specific training requirements prior to accessing any of this information.`,
  INFO_CONTENT_ETHNICITY:
    'Hispanic or Latino ethnicity refers to those who trace their origin to Mexico, Puerto Rico, Cuba, Central or South America, or other Spanish cultures regardless of race.',
  INFO_CONTENT_GENDER_IDENTITY:
    "One's innermost concept of self as male, female, a blend of both or neither - how individuals perceive themselves and what they call themselves. One's gender identity can be the same or different from their sex assigned at birth.",
  INFO_CONTENT_SUCCESS: 'Your changes have been saved.',

  INFO_HEADING_BACKGROUND_INFORMATION: 'REL-SOGI Disclosure',
  INFO_HEADING_ETHNICITY: 'Ethnicity',
  INFO_HEADING_GENDER_IDENTITY: 'Gender Identity',
  INFO_HEADING_SUCCESS: 'Success',
  INFO_TOOLTIP: 'Information Tooltip',
  /* Common Input Values */
  INPUT_DECLINE: 'Choose not to answer',
  INPUT_ERROR_REQUIRED: 'This field is required',
  INPUT_ERROR_MAX_TWO: 'Please only select up to two items.',
  INPUT_ERROR_FORM: 'Please resolve all of the above form errors to continue.',
  INPUT_OTHER: 'Other',
  INPUT_OTHER_REQUIRED: 'Other (required)',
  INPUT_UNKNOWN: 'Unknown',
  INPUT_UNSURE: "Don't know",
  /* Language Section */
  LANGUAGE_ASL: 'American Sign Language',
  LANGUAGE_ENGLISH: 'English',
  LANGUAGE_HOME_LEGEND: 'What is the primary language spoken at home?',
  LANGUAGE_OTHER_PRIMARY: 'Other Primary Language',
  LANGUAGE_SPANISH: 'Spanish',
  LANGUAGE_SPEAKING_LEGEND:
    'What language do you feel most comfortable speaking?',
  LANGUAGE_WRITTEN_LEGEND: 'What is your preferred written language?',
  RACE_ETHNICITY_LANGAUGE_SAME_FOR_ALL_DEPENDENTS:
    'Race, ethnicity, and language information is the same for all dependents.',
  /* Sexual Orientation Section */
  ORIENTATION_LEGEND: 'What is your sexual orientation?',
  ORIENTATION_HETEROSEXUAL: 'Straight or Heterosexual',
  ORIENTATION_HOMOSEXUAL: 'Lesbian, Gay, or Homosexual',
  ORIENTATION_BISEXUAL: 'Bisexual',
  /* Pronouns Section */
  PRONOUNS_LEGEND: "What are your/the member's preferred pronouns?",
  PRONOUNS_HE: 'He/Him/His',
  PRONOUNS_SHE: 'She/Her/Hers',
  PRONOUNS_THEY: 'They/Them/Theirs',
  /* Race Section */
  RACE_ASIAN: 'Asian',
  RACE_BLACK: 'Black or African American',
  RACE_LEGEND: 'Race (select up to two)',
  RACE_NATIVE: 'American Indian or Alaskan Native',
  RACE_PI: 'Native Hawaiian/Other Pacific Islander',
  RACE_WHITE: 'White',
  /* Sex at Birth Section */
  SEX_FEMALE: 'Female',
  SEX_LEGEND: 'What sex was originally listed on your birth certificate?',
  SEX_MALE: 'Male',
  /* Speaking English Section */
  SPEAK_ENGLISH_LEGEND: 'How well do you speak English?',
  SPEAK_ENGLISH_HIGH: 'Very well',
  SPEAK_ENGLISH_MEDIUM: 'Well',
  SPEAK_ENGLISH_LOW: 'Not well',
  SPEAK_ENGLISH_NONE: 'Not at all',
};
