import { getBackgroundInformation } from 'api/get-background-information';
import { postBackgroundInformation } from 'api/post-background-information';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { DEFAULT_PARTNER_ID, QUERY_KEYS } from 'common/constants';
import { BackgroundInformationFormData } from 'components/background-information/background-information-constants';
import { useCurrentRegionUbkHeader } from 'hooks/use-current-region-ubk-header.hook';
import { useGetIdentityPartnerToken } from '../use-get-identity-partner-token';
import { denormalizeBackgroundInformation } from './utils/denormalize-background-information';
import { normalizeBackgroundInformation } from './utils/normalize-background-information';

export function useBackgroundInformation() {
  const { data: identityTokenData } =
    useGetIdentityPartnerToken(DEFAULT_PARTNER_ID);

  const ubkHeader = useCurrentRegionUbkHeader();

  const backgroundInformationQuery = useQuery(
    [QUERY_KEYS.BACKGROUND_INFORMATION],
    () => getBackgroundInformation(identityTokenData, ubkHeader),
    {
      enabled: !!identityTokenData,
      select(response) {
        const denormalizedProfileAndPreferences =
          denormalizeBackgroundInformation(response.backgroundInfo);

        return {
          memberId: response.backgroundInfo.memberId,
          backgroundInfo: denormalizedProfileAndPreferences,
        };
      },
    },
  );
  return backgroundInformationQuery;
}

export type BackgroundInformationResponse = ReturnType<
  typeof useBackgroundInformation
>['data'];

export function useSubmitBackgroundInformation() {
  const queryClient = useQueryClient();
  const { data: identityTokenData } =
    useGetIdentityPartnerToken(DEFAULT_PARTNER_ID);

  const ubkHeader = useCurrentRegionUbkHeader();

  const submitBackgroundInformation = useMutation({
    mutationFn: async ({
      memberId,
      backgroundInformation,
    }: {
      memberId: string;
      backgroundInformation: BackgroundInformationFormData;
    }) => {
      const normalizedBackgroundInformation = normalizeBackgroundInformation({
        memberId,
        backgroundInformation,
      });

      await postBackgroundInformation(
        identityTokenData,
        normalizedBackgroundInformation,
        ubkHeader,
      );
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.BACKGROUND_INFORMATION],
      });
    },
  });

  return submitBackgroundInformation;
}
