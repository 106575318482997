import { BACKGROUND_INFORMATION_FORM_GROUPS } from '../background-information-constants';
import {
  RadioButtonGroup,
  FormGroupOptions,
} from '../radio-button-group.component';

const pronounsRadioBoxes: FormGroupOptions = [
  {
    label: 'PRONOUNS_HE',
    value: 'HE/HIM',
  },
  {
    label: 'PRONOUNS_SHE',
    value: 'SHE/HER',
  },
  {
    label: 'PRONOUNS_THEY',
    value: 'THEY/THEM',
  },
  {
    label: 'INPUT_OTHER',
    value: 'OTHER (PRONOUNS)',
  },
  {
    label: 'INPUT_DECLINE',
    value: 'CHOOSE NOT TO DISCLOSE (PRONOUNS)',
  },
];

export const PronounsGroup = ({ pin }: { pin: string }) => {
  const name = `${pin}.${BACKGROUND_INFORMATION_FORM_GROUPS.PRONOUNS}`;

  return (
    <RadioButtonGroup
      key={name}
      name={name}
      legend="PRONOUNS_LEGEND"
      radioBoxes={pronounsRadioBoxes}
    />
  );
};
