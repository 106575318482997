import { CurrentUbkHeader } from 'hooks/use-current-region-ubk-header.hook';
import { highmarkFetch } from '../utils/highmark-fetch';

const HEALTH_SERVICES_URL = '/v1/members/health/services';

export const getHealthServices = async (
  partnerAccessToken: string,
  currentUbkHeader: CurrentUbkHeader,
) => {
  const response = await highmarkFetch(
    HEALTH_SERVICES_URL,
    partnerAccessToken,
    { ...currentUbkHeader },
  );
  return response.json();
};
