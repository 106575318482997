import { getAppInitDetails } from 'api/get-app-init-details';

export const getRegionUbkValue = async () => {
  let regionUbkObject = {};
  await getAppInitDetails().then((data) => {
    const brandUbk = data.currentUbk?.brandUbk || '';
    if (brandUbk) {
      regionUbkObject = { brandUbk };
    }
  });
  return regionUbkObject;
};
