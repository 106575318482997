import { Box, UtilityText } from '@leagueplatform/genesis-core';
import { MAX_DISPLAY_MESSAGES_COUNT } from 'common/constants';

type UnreadMessageIndicatorProps = {
  unreadMessageCount: number;
  children: React.ReactNode;
};

export function UnreadMessageIndicator({
  unreadMessageCount,
  children,
}: UnreadMessageIndicatorProps) {
  return (
    <Box
      css={{
        position: 'relative',
        width: 'fit-content',
      }}
    >
      {unreadMessageCount > 0 ? (
        <Box
          css={{
            position: 'absolute',
            right: 0,
            transform: 'translate(30%)',
            borderRadius: '50%',
            backgroundColor: '$criticalIcon',
            pointerEvents: 'none',
            width: 23,
            height: 23,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <UtilityText
            aria-hidden
            css={{
              typography: '$caption',
              color: '$onSurfaceTextReversed',
            }}
          >
            {unreadMessageCount > MAX_DISPLAY_MESSAGES_COUNT
              ? `${MAX_DISPLAY_MESSAGES_COUNT}+`
              : unreadMessageCount}
          </UtilityText>
        </Box>
      ) : null}
      {children}
    </Box>
  );
}
