import * as React from 'react';
import { useEffect, useState } from 'react';
import { ExpandableGroupHeader } from 'components/background-information/expandable-group-header';
import { RegisterOptions, useFormContext } from '@leagueplatform/web-common';
import { Fieldset } from '@leagueplatform/genesis-core';
import { isOtherValueField } from 'hooks/use-background-information/utils/is-other-value-field';
import { CheckboxRowInput } from './checkbox-row-input.component';
import { OtherCheckboxButton } from './other-checkbox.component';
import { getFormFieldError } from './utils/get-form-field-errors';
import { useInputValidationState } from './hooks/use-input-validation-state';
import { INFO_MODAL_TYPES } from './info-modal-popover.component';

type FormGroupOption = {
  label: string;
  value: string;
};
export type FormGroupOptions = FormGroupOption[];

type CheckboxGroupProps = {
  name: string;
  legend: string;
  options: FormGroupOptions;
  inputRules: RegisterOptions;
  infoModal?: INFO_MODAL_TYPES;
};

export const CheckboxGroup = ({
  name,
  options,
  legend,
  inputRules,
  infoModal,
}: CheckboxGroupProps) => {
  const { register, formState } = useFormContext();
  const fieldName = `${name}.value`;
  const otherInputValueName = `${name}.otherValue`;
  const checkboxError = getFormFieldError(name, formState.errors);
  const inputError = getFormFieldError(otherInputValueName, formState.errors);
  const hasErrors = !!checkboxError || !!inputError;
  const [isExpanded, setIsExpanded] = useState(hasErrors);
  const error = getFormFieldError(fieldName, formState.errors);
  const { inputStatus, statusMessage } = useInputValidationState(error);

  useEffect(() => {
    if (hasErrors) {
      setIsExpanded(true);
    }
  }, [hasErrors]);

  const checkboxInputField = React.useMemo(
    () => register(fieldName, inputRules),
    [fieldName, register, inputRules],
  );

  return (
    <>
      <ExpandableGroupHeader
        legend={legend}
        isExpanded={isExpanded}
        handleClick={() => setIsExpanded((prev) => !prev)}
        infoModal={infoModal}
      />
      <Fieldset
        legend={legend}
        hideLegend
        id={fieldName}
        css={{ display: isExpanded ? 'block' : 'none', paddingBottom: '$one' }}
        inputStatus={inputStatus}
        statusMessage={statusMessage}
      >
        {options.map(({ label, value }) => {
          if (isOtherValueField(value)) {
            return (
              <OtherCheckboxButton
                key={value}
                value={value}
                inputProps={{
                  name: otherInputValueName,
                  placeholder: label,
                  label,
                }}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...checkboxInputField}
              />
            );
          }

          return (
            <CheckboxRowInput
              key={value}
              id={`${fieldName}:${value}`}
              label={label}
              value={value}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...checkboxInputField}
            />
          );
        })}
      </Fieldset>
    </>
  );
};
