import { BACKGROUND_INFORMATION_FORM_GROUPS } from '../background-information-constants';
import {
  RadioButtonGroup,
  FormGroupOptions,
} from '../radio-button-group.component';

const educationRadioBoxes: FormGroupOptions = [
  {
    label: 'EDUCATION_ELEMENTARY',
    value: '8TH GRADE OR LESS',
  },
  {
    label: 'EDUCATION_SOME_HS',
    value: 'SOME HIGH SCHOOL, BUT DID NOT GRADUATE',
  },
  {
    label: 'EDUCATION_HS',
    value: 'HIGH SCHOOL GRADUATE OR GED',
  },
  {
    label: 'EDUCATION_SOME_COLLEGE',
    value: 'SOME COLLEGE OR 2-YEAR DEGREE',
  },
  {
    label: 'EDUCATION_COLLEGE',
    value: '4-YEAR COLLEGE GRADUATE',
  },
  {
    label: 'EDUCATION_COLLEGE_PLUS',
    value: 'MORE THAN 4-YEAR COLLEGE DEGREE',
  },
  {
    label: 'INPUT_DECLINE',
    value: 'CHOOSE NOT TO ANSWER (EDUCATION COMPLETION LEVEL)',
  },
];

export const EducationGroup = ({ pin }: { pin: string }) => {
  const name = `${pin}.${BACKGROUND_INFORMATION_FORM_GROUPS.EDUCATION}`;

  return (
    <RadioButtonGroup
      key={name}
      name={name}
      legend="EDUCATION_LEGEND"
      radioBoxes={educationRadioBoxes}
    />
  );
};
