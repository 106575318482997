import { useIntl } from '@leagueplatform/locales';
import care from 'assets/walkthrough-slides/care.svg';
import journey from 'assets/walkthrough-slides/journey.svg';
import profile from 'assets/walkthrough-slides/profile.svg';
import benefits from 'assets/walkthrough-slides/benefits.svg';
import personalized from 'assets/walkthrough-slides/personalized.svg';
import { SlideData, useGetAppInitDetails } from '@highmark-web/highmark-common';

export const useGetWalkthroughSlideContent = () => {
  const { formatMessage } = useIntl();
  const { brandingInfo, isLoading } = useGetAppInitDetails();

  const slideImages: { [index: number]: string } = {
    0: brandingInfo?.brandingLogoOpt || brandingInfo?.brandingLogo || '',
    1: journey,
    2: benefits,
    3: care,
    4: profile,
    5: personalized,
  };

  const slideHeadingContents: { [index: number]: string } = {
    0: formatMessage({ id: 'APP_WALKTHROUGH_0_HEADING' }),
    1: formatMessage({ id: 'APP_WALKTHROUGH_1_HEADING' }),
    2: formatMessage({ id: 'APP_WALKTHROUGH_2_HEADING' }),
    3: formatMessage({ id: 'APP_WALKTHROUGH_3_HEADING' }),
    4: formatMessage({ id: 'APP_WALKTHROUGH_4_HEADING' }),
    5: formatMessage({ id: 'APP_WALKTHROUGH_5_HEADING' }),
  };

  const slideBodyContents: { [index: number]: string } = {
    0: brandingInfo?.brandingMessage || '',
    1: formatMessage({ id: 'APP_WALKTHROUGH_1_BODY' }),
    2: formatMessage({ id: 'APP_WALKTHROUGH_2_BODY' }),
    3: formatMessage({ id: 'APP_WALKTHROUGH_3_BODY' }),
    4: formatMessage({ id: 'APP_WALKTHROUGH_4_BODY' }),
    5: formatMessage({ id: 'APP_WALKTHROUGH_5_BODY' }),
  };

  const slideData: SlideData = {
    slideImages,
    slideHeadingContents,
    slideBodyContents,
  };

  return { slideData, brandingIsLoading: isLoading };
};
