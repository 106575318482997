import React, { useState } from 'react';
import {
  Button,
  HeadingText,
  Icon,
  Modal,
  ParagraphText,
  StackLayout,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';

export const SuccessModalPopover = ({ isSuccess }: { isSuccess: boolean }) => {
  const { formatMessage } = useIntl();
  const [isOpen, setIsOpen] = useState(isSuccess);

  return (
    <Modal.Root open={isOpen} onOpenChange={() => setIsOpen((prev) => !prev)}>
      {/* The CSS is needed to ensure this modal and overlay are higher in the z-index than any already open modals */}
      <Modal.Content
        showCloseButton={false}
        css={{
          '.GDS-modal': {
            width: '381px',
            height: '280px',
            zIndex: '$toast',
            borderRadius: '0px',
          },
          '.GDS-modal-overlay': { zIndex: '$toast' },
        }}
      >
        <Modal.Title>
          {formatMessage({ id: 'INFO_HEADING_SUCCESS' })}
        </Modal.Title>
        <StackLayout horizontalAlignment="center">
          <Icon
            icon="statusSuccessStatusFilled"
            size="$three"
            tint="$successIcon"
            css={{ marginY: '$one' }}
          />
          <HeadingText
            level="1"
            size="xl"
            css={{ paddingBottom: '$oneAndHalf' }}
          >
            {formatMessage({ id: 'INFO_HEADING_SUCCESS' })}
          </HeadingText>
          <ParagraphText
            size="sm"
            css={{
              whiteSpace: 'pre-wrap',
              paddingBottom: '$one',
            }}
          >
            {formatMessage({ id: 'INFO_CONTENT_SUCCESS' })}
          </ParagraphText>
          <Button
            onClick={() => setIsOpen(false)}
            priority="tertiary"
            quiet
            size="medium"
          >
            {formatMessage({ id: 'BACKGROUND_INFORMATION_ACCEPT' })}
          </Button>
        </StackLayout>
      </Modal.Content>
    </Modal.Root>
  );
};
