import { useMutation, useQueryClient } from 'react-query';
import { DEFAULT_PARTNER_ID, QUERY_KEYS } from 'common/constants';
import { useCurrentRegionUbkHeader } from 'hooks/use-current-region-ubk-header.hook';
import { HsaRegistrationData } from './use-get-hsa-registration-eligibility.hook';
import { useGetIdentityPartnerToken } from './use-get-identity-partner-token';
import { postHsaEnrollment } from '../api/post-hsa-enrollment';

export const useOpenHsaAccount = () => {
  const { data: identityTokenData } =
    useGetIdentityPartnerToken(DEFAULT_PARTNER_ID);

  const queryClient = useQueryClient();
  const ubkHeader = useCurrentRegionUbkHeader();

  const { isSuccess, isLoading, isError, data, mutateAsync } = useMutation(
    ['home', 'hsa'],
    (hsaEligibility: HsaRegistrationData) =>
      postHsaEnrollment(identityTokenData, hsaEligibility, ubkHeader),
    {
      onSuccess: () => {
        // invalidate the user's registration eligibility data once it has been updated
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.HSA_ELIGIBILITY_KEY],
        });
      },
    },
  );

  // both a success response and `data.message === 'HSA-ENROLLINPROG'` count as successful responses
  const isValidSuccessResponse =
    data?.response === 'success' || data?.message === 'HSA-ENROLLINPROG';
  const isSuccessResponse = isSuccess && isValidSuccessResponse;
  // return an error when the mutation is successful but the response is invalid
  const isErrorResponse = isError || (isSuccess && !isValidSuccessResponse);

  return {
    isLoading,
    isError: isErrorResponse,
    isSuccess: isSuccessResponse,
    mutateAsync,
  };
};
