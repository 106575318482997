import { useEffect, useState } from 'react';
import { ExpandableGroupHeader } from 'components/background-information/expandable-group-header';
import { Box } from '@leagueplatform/genesis-core';
import { RadioRowInput } from 'components/background-information/radio-row-input.component';
import { ControlledFieldset, useFormContext } from '@leagueplatform/web-common';
import { isOtherValueField } from 'hooks/use-background-information/utils/is-other-value-field';
import { OtherRadioButton } from './other-radio-button.component';
import { getFormFieldError } from './utils/get-form-field-errors';
import { INFO_MODAL_TYPES } from './info-modal-popover.component';

type FormGroupOption = {
  label: string;
  value: string;
};
export type FormGroupOptions = FormGroupOption[];

type RadioButtonGroupProps = {
  name: string;
  legend: string;
  radioBoxes: FormGroupOptions;
  infoModal?: INFO_MODAL_TYPES;
  children?: React.ReactNode;
};

export const RadioButtonGroup = ({
  name,
  radioBoxes,
  legend,
  infoModal,
  children,
}: RadioButtonGroupProps) => {
  const { formState } = useFormContext();
  const fieldName = `${name}.value`;
  const otherInputValueName = `${name}.otherValue`;
  const radioButtonError = getFormFieldError(name, formState.errors);
  const inputError = getFormFieldError(otherInputValueName, formState.errors);
  const hasErrors = !!radioButtonError || !!inputError;
  const [isExpanded, setIsExpanded] = useState(hasErrors);

  useEffect(() => {
    if (hasErrors) {
      setIsExpanded(true);
    }
  }, [hasErrors]);

  return (
    <>
      <ExpandableGroupHeader
        legend={legend}
        isExpanded={isExpanded}
        handleClick={() => setIsExpanded((prev) => !prev)}
        infoModal={infoModal}
      />
      <ControlledFieldset
        legend={legend}
        hideLegend
        name={fieldName}
        id={fieldName}
        css={{ display: isExpanded ? 'block' : 'none', paddingBottom: '$one' }}
        inputOptions={{}}
      >
        {radioBoxes.map(({ label, value }) => {
          if (isOtherValueField(value)) {
            return (
              <OtherRadioButton
                key={value}
                name={fieldName}
                value={value}
                inputProps={{
                  name: otherInputValueName,
                  placeholder: label,
                  label,
                }}
              />
            );
          }
          return (
            <RadioRowInput
              key={value}
              id={`${fieldName}:${value}`}
              name={fieldName}
              label={label}
              value={value}
            />
          );
        })}
      </ControlledFieldset>
      {children ? (
        <Box
          css={{
            display: isExpanded ? 'block' : 'none',
            paddingBottom: '$one',
          }}
        >
          {children}
        </Box>
      ) : null}
    </>
  );
};
