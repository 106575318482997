import { memo } from 'react';
import { Box, Image } from '@leagueplatform/genesis-commons';
import { useGetAppInitDetails } from '@highmark-web/highmark-common';

export const GroupLogo = memo(() => {
  const { brandingInfo } = useGetAppInitDetails();
  return (
    <Box maxHeight={30}>
      <Image
        src={brandingInfo?.brandingLogo as string}
        alt={brandingInfo?.brandingName as string}
        height="100%"
        width="auto"
      />
    </Box>
  );
});
