import { genesisStyled, Link } from '@leagueplatform/genesis-commons';
import { useIntl } from '@leagueplatform/locales';
import { APP_MANAGED_ROUTES } from 'common/app-managed-routes';

export const TermsLinksContent = () => {
  const { formatMessage } = useIntl();

  const AnchorLink = genesisStyled(Link)`
    background-color: inherit;
    border: none;
    font-weight: bold;
    text-decoration: underline;
`;

  return (
    <>
      <span>{formatMessage({ id: 'TERMS_AGREEMENT' })}</span>
      <AnchorLink
        href={APP_MANAGED_ROUTES.LEGAL_PRIVACY_POLICY}
        target="_blank"
      >
        {formatMessage({ id: 'PRIVACY_POLICY' })}
      </AnchorLink>
      <span>&nbsp;{formatMessage({ id: 'AND' })}&nbsp;</span>
      <AnchorLink
        href={APP_MANAGED_ROUTES.LEGAL_TERMS_OF_SERVICE}
        target="_blank"
      >
        {formatMessage({ id: 'TERMS_SERVICE' })}
      </AnchorLink>
      <span>.</span>
    </>
  );
};
