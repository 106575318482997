/**
 * Extracts a value from an object using a react-hook-form field path.
 * Similar to https://lodash.com/docs/4.17.15#get
 */
export function getFormFieldError(path: string, obj: unknown) {
  const splitPath = path.split('.');

  let o = obj;
  // eslint-disable-next-line no-restricted-syntax
  for (const part of splitPath) {
    // @ts-ignore
    o = o[part];

    if (!o) {
      return undefined;
    }
  }

  return o;
}
