import { LiveChatContainer, useLiveChatCta } from '@leagueplatform/live-chat';
import { LiveChatCTA } from './live-chat-cta.component';

export function LiveChat() {
  const { isVisible } = useLiveChatCta();

  return (
    <>
      {isVisible ? <LiveChatCTA /> : null}
      <LiveChatContainer />
    </>
  );
}
