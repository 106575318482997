import { MainPageContainer } from '@leagueplatform/web-common-components';
import {
  css,
  genesisStyled,
  HeadingFour,
  HeadingOne,
  HeadingThree,
  HeadingTwo,
  Link,
  Text,
} from '@leagueplatform/genesis-commons';
import React from 'react';
import { usePrivacyListContent } from './use-privacy-copy.hook';
import { StaticPageWrapper } from '../static-page-wrapper.component';

const StyledUl = genesisStyled.ul(
  css({
    paddingX: 'twoAndHalf',
    paddingY: 'half',
    listStyle: 'disc',
  }),
);

const StyledLi = genesisStyled.li(
  css({
    paddingY: 'half',
  }),
);

const StyledP = genesisStyled(Text)(
  css({
    paddingY: 'half',
  }),
);

const StyledH3 = genesisStyled(HeadingThree)(
  css({
    marginY: 'one',
  }),
);

const StyledH4 = genesisStyled(HeadingFour)(
  css({
    marginTop: 'one',
  }),
);

const generateLink = (url: string, name: string = url) => (
  <Link href={url} target="_blank">
    {name}
  </Link>
);

const PrivacyIntro = () => {
  const { intro } = usePrivacyListContent();

  return (
    <section className="privacy-intro">
      <StyledP>
        We take the issue of online privacy very seriously. And you should, too.
        All the information we collect from you -- our users -- or that you
        provide to us is secured and maintained in accordance with a variety of
        state and federal laws and regulations, as well as our robust corporate
        standards. What follows are the details, sometimes a lot of them,
        concerning the information we collect, use, and disclose (and why), and
        our approach to maintaining your privacy. Transparency is our goal and
        achieving that and keeping you informed requires some length, so we urge
        you to read this document in its entirety.
      </StyledP>
      <StyledH4>Some Important Definitions You’ll Need To Understand</StyledH4>
      <StyledP>
        There are key definitions you’ll meet again and again in this document,
        and you’ll want to understand them up front -- consumer platforms,
        users, you/your, personal information, and third party/third parties.
      </StyledP>
      <StyledUl>
        <StyledLi>{intro.li1}</StyledLi>
      </StyledUl>
      <StyledP>
        If you have questions about whether this Online Privacy Policy or an NPP
        applies to collection, use, or disclosure of information, please contact
        us using the information provided in section 5 below.
      </StyledP>
      <StyledUl>
        {intro.li2.map((item) => (
          <StyledLi key={item.slice(0, 25)}>
            <Text>{item}</Text>
          </StyledLi>
        ))}
      </StyledUl>
    </section>
  );
};

const DataCollection = () => {
  const { dataCollection } = usePrivacyListContent();

  return (
    <section className="data-collection">
      <StyledH3>1. What We Collect</StyledH3>
      <StyledH4>General</StyledH4>
      <StyledP>
        We collect personal information from and about you in a number of ways.
        We leverage online forms, secure portals, third party links/icons,
        interactive chat, biometric login, location services, mobile device
        data, and cookie and tag technologies to collect personal information.
      </StyledP>
      <StyledP>
        How you interact with a particular Plan consumer platform will generally
        determine the type and amount of personal information we collect. For
        general website browsing, we may capture limited personal information
        such as your browser type, IP address, device hardware model, as well as
        server log information such as session time, click streams, and crash
        reports. For other features, we may need to verify your identity through
        a login process and collect sufficient personal information to provide a
        response or administer the service requested.
      </StyledP>
      <StyledP>
        What follows below in Sections 1 and 2 are further details regarding the
        personal information we collect with our information-gathering tools,
        and our specific and general uses and access to and disclosure of your
        personal information associated with those tools.
      </StyledP>
      <StyledH4>Online Forms</StyledH4>
      <StyledP>
        The Plan invites users to contact us using inquiry forms available on
        our corporate-owned platforms for account questions or to learn more
        about our products and services. The personal information we request on
        inquiry forms generally includes your name, address, phone number, email
        address, and the details of your inquiry. We may use such information to
        review and respond to your request or communication, or use contracted
        service providers to do that for us. We may also use information
        collected through online forms as stated in Section 2 below.
      </StyledP>
      <StyledH4>Secure Portals</StyledH4>
      <StyledP>
        The Plan has established secure portals for use by members and patients.
        When you access them to review your health and benefit-related
        information or to contact your health plan or physician’s office
        regarding certain inquiries, such as reviewing claims or requesting
        prescription refills, we collect certain personal information, such as
        your user ID and password, IP address, click streams, and cookie ID.
        Communications sent by or to members or patients who choose to use these
        secure portals may also be recorded in transaction logs to monitor
        content, compliance with applicable law and regulations, or
        functionality of the services. If the information collected is deemed to
        be PHI as noted above, its use and disclosure will be subject to HIPAA
        and an applicable NPP. We may also use information collected through
        secure portals pre-password as stated in Section 2 below.
      </StyledP>
      <StyledH4>Interactive Chat</StyledH4>
      <StyledP>
        Our consumer platforms may offer interactive chat technology to assist
        users. That interactive technology may collect personal information such
        as name, date of birth, address, and account number for authentication
        purposes or to provide specific plan benefit details in a personalized
        response. It may also capture session-related information such as web
        logs to document the interaction. If the information collected is deemed
        to be PHI as noted above, its use and disclosure will be subject to
        HIPAA and an applicable NPP. We may also use information collected
        through interactive chat pre- password as stated in Section 2 below.
      </StyledP>
      <StyledH4>Biometric Login</StyledH4>
      <StyledP>
        You may be invited by your mobile device to use fingerprint, facial
        recognition, or similar recognition and biometric technology to login to
        our consumer platforms. When a biometric login is enabled, our consumer
        platforms recognize that you have selected this as a preference and have
        been authenticated through your mobile device and you are permitted
        access. When you use biometric login functionality on our consumer
        platforms, we do not collect any of the actual biometrics (e.g.,
        fingerprints or facial images); that is managed and maintained on your
        mobile device and by the mobile device manufacturer (e.g., Apple,
        Samsung).
      </StyledP>
      <StyledH4>Geolocation Functionality</StyledH4>
      <StyledP>
        Our consumer platforms may use the location services functionality on
        your mobile device and thereby collect your geolocation data. We use
        geolocation data to assist you in finding local care sites,
        communicating about geographically-based products and services, and
        other relevant content based on your location. We may also use
        information collected through location services as stated in Section 2
        below.
      </StyledP>
      <StyledH4>Mobile Device</StyledH4>
      <StyledP>
        Our consumer platforms may collect certain personal information when
        being run on a mobile device; for example, if one of our mobile
        applications is downloaded, we may collect information about the device
        type, its software/operating system, and device identifier. We use this
        information to assess and analyze information about our general user
        base and to improve our technical support capabilities. We may also use
        information collected from your mobile device as stated in Section 2
        below.
      </StyledP>
      <StyledH4>Cookies -- yes, we use them</StyledH4>
      <StyledP>
        A cookie is a small text file that is stored on a computer or other
        internet-connected device when it accesses a digital resource. Cookies
        can capture user information such as IP address, internet browser and
        operating system type, the date and time of a digital interaction,
        session information such as page response times, your search history,
        saved preferences and password information (if a user elects to have a
        website remember this information), information about the referring
        uniform resource locator (URL), click stream to and through and from our
        consumer platforms, and similar details.
      </StyledP>
      <StyledP>
        The Plan’s consumer platforms may use first-party cookies (i.e., ones we
        create) to support our digital resources, monitor their performance,
        enhance the user experience, and assess information about our user base
        to help inform our decisions about content delivery. We may gather and
        use information obtained from first-party cookies to provide customers
        and prospects with tailored messaging. We may also employ cookies on
        third party websites to facilitate the delivery of our services and help
        study users’ activities online over time.
      </StyledP>
      <StyledP>
        The Plan may use third party advertising cookies to serve our ads on
        other websites and digital properties. Advertising companies may also
        use information obtained from cookies placed on your device in order to
        measure advertising effectiveness and to provide advertisements they
        deem of interest to you on other platforms. If you would like to review
        and manage and/or opt-out of third party cookies used for targeted
        advertising, you may navigate to the following links provided by the
        Network Advertising Initiative (
        {generateLink('http://optout.networkadvertising.org/?c=1')}) and the
        Digital Advertising Alliance (
        {generateLink('http://optout.aboutads.info/?c=2&lang=EN')}).
      </StyledP>
      <StyledP>
        A third party, such as Twitter or LinkedIn, may employ cookies on our
        consumer platforms to facilitate the delivery of their services and help
        follow your online activities over time.
      </StyledP>
      <StyledP>
        Subsequent use of cookie data by third parties is subject to their
        online privacy policy and/or terms of use, and you are encouraged to
        review those documents.
      </StyledP>
      <StyledP>
        Cookies that may be employed on our consumer platforms include the
        following types:
      </StyledP>
      <StyledUl>
        {dataCollection.cookiesLi.map((item) => (
          <StyledLi key={item.slice(0, 25)}>
            <Text>{item}</Text>
          </StyledLi>
        ))}
      </StyledUl>
      <StyledP>
        Most internet browser settings can be modified by users to attempt to
        block cookies (e.g., choosing a “do not track” option). If you choose to
        block cookies using your browser settings, The Plan’s consumer platforms
        may not respond to these choices. Also, you should be aware that
        blocking cookies could prevent a particular consumer platform or certain
        features from fully functioning. We encourage you to keep cookies
        enabled for an optimized user experience.
      </StyledP>
      <StyledH4>Third Party Platforms</StyledH4>
      <StyledP>
        Some of the features and components you encounter on our consumer
        platforms are owned and controlled by third parties. That means we do
        not manage data collection, use, or disclosure activities within their
        features or components, even though we may receive information from them
        about you. Here’s an example: The Plan maintains a Facebook page, but we
        have no control over how Facebook, as a third party, collects, uses, or
        discloses information obtained from users when they visit that page.
        Here’s another one: One of our mobile applications can be downloaded or
        updated through Apple or Google, but we have no control over Apple’s or
        Google’s collection of information from your mobile device during that
        download or update.
      </StyledP>
      <StyledP>
        Because our Online Privacy Policy does not cover third party platforms
        or components, we encourage you to be informed and read all third party
        privacy policies in addition to ours. As we stated up top, you should
        take your online privacy seriously.
      </StyledP>
      <StyledP>
        When you link to a third party platform or component, this Plan’s Online
        Privacy Policy will no longer govern. Instead, the privacy policy and/or
        terms of use established by the third party are in force. By clicking
        hyperlinks or icons located on our consumer platforms, you may access or
        inquire about products and services offered by third parties. We may
        share some of your personal information, such as IP address, when you
        navigate to these external platforms or components to enable
        identification across digital properties. The Plan makes no
        representations or warranties regarding third party platforms or
        components, their content, data management, or security. To be an
        informed consumer, you are reminded again to review the online privacy
        policy and/or terms of use of third parties.
      </StyledP>
    </section>
  );
};

const PersonalInfo = () => {
  const { personalInfo } = usePrivacyListContent();

  return (
    <section className="personal-info">
      <StyledH3>
        2. General Uses And Access To and Disclosure Of Personal Information
      </StyledH3>
      <StyledP>
        The Plan uses your personal information collected through our consumer
        platforms for all the specific purposes stated in Section 1 above.
        Additional general uses include to:
      </StyledP>
      <StyledUl>
        {personalInfo.intro.map((item) => (
          <StyledLi key={item.slice(0, 25)}>
            <Text>{item}</Text>
          </StyledLi>
        ))}
      </StyledUl>
      <StyledP>
        We may also use your personal information to provide you with access to
        information about additional products, programs, and services offered by
        our family of companies or our business partners. We will use the
        contact information you provide to communicate with you via phone,
        email, text, and/or regular mail, according to your preferences. You may
        remove yourself from certain communication channels at any time -- just
        follow the opt-out instructions included in those specific
        communications.
      </StyledP>
      <StyledH4>Access By Employees</StyledH4>
      <StyledP>
        Personal information of our users is utilized to conduct routine
        business operations. Employees of The Plan and its family of companies
        are required to maintain the confidentiality of your personal
        information and to use strict standards of care in handling this
        information. This is enforced by written confidentiality statements,
        corporate policies, training, and state or federal laws or regulations.
        Employees who do not conform to these requirements are subject to
        disciplinary sanctions.
      </StyledP>
      <StyledH4>Disclosure To Service Providers</StyledH4>
      <StyledP>
        The Plan may disclose your personal information collected through its
        consumer platforms to service providers that are contracted by The Plan.
        The Plan’s service providers are legally bound by contract to follow the
        same or similar standards of confidentiality as followed by The Plan,
        and to handle your personal information with due care.
      </StyledP>
      <StyledH4>Disclosure To Third Parties</StyledH4>
      <StyledP>
        Other than as set forth in Section 1 above, and as permitted or required
        by law, The Plan generally does not disclose personal information
        collected through its consumer platforms to third parties without the
        permission of the user. Personal information may be disclosed to a third
        party if there is a specific legal basis, if there is a need to complete
        a transaction requested by the user, or if necessary for providing a
        service or benefit to the user. For example, personal Information such
        as IP address may be disclosed to third party advertising networks to
        display ads of interest to you.
      </StyledP>
      <StyledH4>
        Disclosure To Comply With Law, Respond To Legal Requests, Prevent Harm,
        and Protect Our Rights
      </StyledH4>
      <StyledP>
        The Plan may disclose your personal information to courts, law
        enforcement, governmental oversight agencies, and other appropriate
        licensure bodies as permitted or required by applicable law, or if such
        disclosure is reasonably necessary to:
      </StyledP>
      <StyledUl>
        {personalInfo.disclosure.map((item) => (
          <StyledLi key={item.slice(0, 25)}>
            <Text>{item}</Text>
          </StyledLi>
        ))}
      </StyledUl>
      <StyledP>
        For instance, personal information may be shared with public health
        authorities to contain the spread of infectious diseases.
      </StyledP>
      <StyledH4>
        California Consumer Privacy Act (CCPA) and Sale of Personal Information
      </StyledH4>
      <StyledP>
        The Plan does not sell your personal information collected through our
        consumer platforms for monetary consideration. However, under some
        circumstances and according to some state laws (such as CCPA), a
        transfer of personal information to third parties, even without monetary
        consideration, may be considered a “sale” of your personal information.
        See Section 4 below for more information regarding CCPA.
      </StyledP>
      <StyledH4>Retention of Personal Information</StyledH4>
      <StyledP>
        All personal information collected as set forth in this Online Privacy
        Policy may be retained for historical and auditing purposes, as well as
        to comply with any applicable legal and/or regulatory record retention
        requirements.
      </StyledP>
      <StyledH4>Anonymizing Personal Information</StyledH4>
      <StyledP>
        Your personal information may be anonymized by The Plan--which means
        stripped of individual identifiers --and aggregated with other data
        sets, and used for internal business purposes without permission.
      </StyledP>
    </section>
  );
};

const SecurityPrivacy = () => (
  <section className="security-privacy">
    <StyledH3>3. Security, Privacy, and Data Governance</StyledH3>
    <StyledH4>Information Security</StyledH4>
    <StyledP>
      The Plan uses reasonable, industry-standard information security practices
      and technology. Security controls include, where appropriate, encryption,
      application/system authentication and access management, network
      firewalls, threat monitoring, incident response, and workforce education.
      Users who communicate with The Plan using unsecured means, such as a
      personal e-mail account or SMS texting, should be aware that there is
      always some risk of the potential interception or misuse of your
      information when communicating in unsecured ways.
    </StyledP>
    <StyledH4>Internal Privacy And Data Ethics Program</StyledH4>
    <StyledP>
      The Plan maintains an internal privacy and data ethics program to help
      ensure compliance with this Online Privacy Policy and all applicable laws.
      This includes oversight by a Chief Privacy and Data Ethics Officer who is
      accountable for the administration of program activities. The privacy and
      data ethics program encompasses workforce training, managing incident
      response, engaging in process design and improvement, conducting third
      party due diligence, offering strategic advice to business segments,
      coordinating legal and regulatory readiness efforts, and proactive
      compliance monitoring.
    </StyledP>
    <StyledH4>Data Governance</StyledH4>
    <StyledP>
      The Plan’s data governance program guides the overall management of the
      availability, usability, integrity, confidentiality, and security of data
      under The Plan’s control and custody. It encompasses the people,
      processes, and technology to streamline and govern the proper handling of
      data.
    </StyledP>
  </section>
);

const OtherData = () => (
  <section className="other-data">
    <StyledH3>4. Other Relevant Data And Consumer Protection Laws</StyledH3>
    <StyledH4>
      Children’s Online Privacy Protection Act (COPPA) and similar state laws
    </StyledH4>
    <StyledP>
      Our consumer platforms are not typically directed at or made available to
      children under the age of 13, and we typically do not make attempts to
      collect, use, or disclose information from children under the age of 13.
      Pursuant to some state laws, minors can independently consent to receive
      medical care without the consent of a parent or legal guardian; in these
      circumstances, some of our consumer platforms, such as secure patient
      portals, are offered to minors for use in connection with their health
      care.
    </StyledP>
    <StyledP>
      The Plan also makes reasonable attempts to comply with applicable state
      laws governing advertising and marketing to children, including the
      Delaware Online Privacy Protection Act, which prohibits marketing to
      children under the age of 18.
    </StyledP>
    <StyledH4>
      European Union General Data Protection Regulation (GDPR)
    </StyledH4>
    <StyledP>
      The Plan has determined that some of our business segments are subject to
      obligations set by the GDPR. For further detail regarding our disclosures
      to data subjects located in the European Union or European Economic Area,
      please navigate to our{' '}
      {generateLink(
        'https://cdn.highmark.com/content/beneficity/policies/GDPR.pdf',
        'GDPR Statement',
      )}
      .
    </StyledP>
    <StyledH4>CCPA</StyledH4>
    <StyledP>
      The Plan has determined that some of our business segments are subject to
      obligations set by the CCPA. For further detail regarding our notice to
      California consumers about the categories and sources of personal
      information collected about them, the purposes for such collection, the
      categories of external recipients to whom personal information is
      disclosed, and the purposes for such disclosure, please navigate to the
      California Consumer Privacy Policy of our covered businesses, which can be
      found{' '}
      {generateLink(
        'https://www.unitedconcordia.com/docs/CCPA_privacy_policy.pdf',
        'here',
      )}{' '}
      and{' '}
      {generateLink(
        'https://www.hmig.com/legal-privacy/california-consumer-privacy-policy/',
        'here',
      )}
      , respectively.
    </StyledP>
    <StyledH4>
      Nevada Privacy of Information Collected on the Internet from Consumers Act
      (NPICICA)
    </StyledH4>
    <StyledP>
      The Plan does not sell covered information as defined by the NPICICA.
      Users who would like to inquire about the selling of covered information
      under the NPICICA can contact us using the information provided in section
      5 below.
    </StyledP>
  </section>
);

const Questions = () => (
  <section className="changes-and-questions">
    <StyledH3>5. Changes To Online Privacy Policy and Questions</StyledH3>
    <StyledP>
      The Plan reserves the right to change, modify, or update this Online
      Privacy Policy at any time and for any reason. The Plan will promptly post
      such changes, modifications, and updates to its consumer platforms
      accordingly. Continued use of our consumer platforms constitutes your
      ongoing acceptance of the terms of our Online Privacy Policy, as well as
      any applicable terms of use/service.
    </StyledP>
    <StyledH4>Questions And Concerns</StyledH4>
    <StyledP>
      If you have questions about this Online Privacy Policy, or concerns
      regarding your personal information, please contact us via&nbsp;
      <Link href="mailto:privacy@highmarkhealth.org">email</Link>
      &nbsp;or calling 1-866-228-9424.
    </StyledP>
    <StyledP>(Last revised December 2022)</StyledP>
  </section>
);

const PrivacyPageContent = () => (
  <MainPageContainer>
    <HeadingOne>Privacy</HeadingOne>
    <HeadingTwo marginY="one">The Plan’s Online Privacy Policy</HeadingTwo>
    <PrivacyIntro />
    <DataCollection />
    <PersonalInfo />
    <SecurityPrivacy />
    <OtherData />
    <Questions />
  </MainPageContainer>
);

export const PrivacyPage = () => (
  <StaticPageWrapper>
    <PrivacyPageContent />
  </StaticPageWrapper>
);
