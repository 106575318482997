import { useQuery } from 'react-query';
import { DEFAULT_PARTNER_ID, QUERY_KEYS } from 'common/constants';
import {
  DemographicsProfile,
  getDemographicsProfile,
} from 'api/get-demographics-profile';
import { useCurrentRegionUbkHeader } from 'hooks/use-current-region-ubk-header.hook';
import { useGetIdentityPartnerToken } from './use-get-identity-partner-token';

export function useDemographicsProfile() {
  const { data: identityTokenData } =
    useGetIdentityPartnerToken(DEFAULT_PARTNER_ID);

  const ubkHeader = useCurrentRegionUbkHeader();

  const backgroundInformationQuery = useQuery(
    [QUERY_KEYS.DEMOGRAPHICS_PROFILE],
    () => getDemographicsProfile(identityTokenData, ubkHeader),
    {
      enabled: !!identityTokenData,
      select({ demographics }) {
        const { pin, firstName, lastName, dateOfBirth } = demographics;

        // Convert the list of profiles into an array with the first index being the subscriber.
        const profiles: DemographicsProfile[] = [
          { pin, firstName, lastName, dateOfBirth },
        ];

        if (demographics.dependents) {
          profiles.push(...demographics.dependents);
        }

        return profiles;
      },
    },
  );
  return backgroundInformationQuery;
}
