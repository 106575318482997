import { FormField } from '@leagueplatform/genesis-core';
import { RegisterOptions, useFormContext } from '@leagueplatform/web-common';
import { BaseInput } from 'components/background-information/base-input.component';
import { getFormFieldError } from './utils/get-form-field-errors';
import { useInputValidationState } from './hooks/use-input-validation-state';

export type InputFieldProps = {
  name: string;
  label: string;
  placeholder: string;
  inputOptions?: RegisterOptions;
};

/**
 * Copied from `modules/web-common/src/lib/react-hook-form/components/controlled-form-field.component.tsx`
 * and modified so that it works with react-hook-form objects.  The one from within the SDK isn't able to handle
 * nested fields using the `a.b.c` notation.
 */
export function InputField({
  name,
  label,
  placeholder,
  inputOptions = {},
}: InputFieldProps) {
  const { register, formState } = useFormContext();
  const error = getFormFieldError(name, formState.errors);
  const required = Boolean(inputOptions?.required);
  const { inputStatus, statusMessage } = useInputValidationState(error);

  return (
    <FormField
      id={name}
      name={name}
      label={label}
      hideLabel
      required={required}
      inputStatus={inputStatus}
      statusMessage={statusMessage}
    >
      <BaseInput
        placeholder={placeholder}
        id={name}
        label={label}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...register(name, inputOptions)}
      />
    </FormField>
  );
}
