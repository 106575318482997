import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { StackLayout } from '@leagueplatform/web-common-components';
import BeneficityIcon from 'assets/beneficity-icon.svg';
import { AboutPage, useGetAppInitDetails } from '@highmark-web/highmark-common';
import { ParagraphText } from '@leagueplatform/genesis-core';
import { toTitleCase } from 'utils/to-title-case';

export const AboutBeneficity = () => {
  const { formatMessage } = useIntl();
  const { brandingInfo } = useGetAppInitDetails();
  const brandingName = toTitleCase(brandingInfo?.brandingName);
  const defaultEmployerName = formatMessage({
    id: 'ABOUT_BENEFICITY_BODY_1_YOUR_EMPLOYER',
  });

  return (
    <AboutPage
      heading={formatMessage({ id: 'ABOUT_BENEFICITY' })}
      image={{ src: BeneficityIcon }}
      body={
        <StackLayout>
          <ParagraphText>
            {formatMessage(
              { id: 'ABOUT_BENEFICITY_BODY_1' },
              { employer: brandingName || defaultEmployerName },
            )}
          </ParagraphText>
          <ParagraphText>
            {formatMessage({ id: 'ABOUT_BENEFICITY_BODY_2' })}
          </ParagraphText>
        </StackLayout>
      }
    />
  );
};
