import { fetchAndActivate, RemoteConfig } from 'firebase/remote-config';
import { useQuery } from 'react-query';
import { captureMessage } from '@leagueplatform/observability';
import { FirebaseRemoteConfigValue } from 'common/constants';

export type FetchAndActivateProps = {
  remoteConfig: RemoteConfig;
  staleTimeOverride?: number | undefined; // should only be used for development/debugging
  cacheTimeOverride?: number | undefined; // should only be used for development/debugging
  defaultValues?: Record<string, FirebaseRemoteConfigValue> | undefined;
};

export const useFetchAndActivate = ({
  remoteConfig,
  staleTimeOverride,
  cacheTimeOverride,
  defaultValues,
}: FetchAndActivateProps) =>
  /* In production by default Firebase throttles fetch requests to once per 12 hours (per session)
   * We also want to use the same flag values throughout a session to ensure a consistent user experience
   * Setting query cache/stale time to infinite ensures only one call to the server per session
   * For development/debugging these values can be overridden to a lower threshold
   * Additionally `remoteConfig.settings.minimumFetchIntervalMillis` should be set - see utils/init-frebase-remote-config.ts
   */

  useQuery('REMOTE_CONFIG', () => fetchAndActivate(remoteConfig), {
    staleTime: staleTimeOverride ?? Infinity,
    cacheTime: cacheTimeOverride ?? Infinity,
    onError(error) {
      // eslint-disable-next-line no-console
      console.log(
        'Fetching firebase remote config failed, using default values instead',
      );
      captureMessage(
        'Fetch firebase remote config failed, use default values instead',
        {
          severityLevel: 'warning',
          errorName:
            (error as Error)?.message || 'error fetching remote config',
          context: {
            Context: { errorInfo: error ?? 'no info available', defaultValues },
          },
        },
      );
    },
  });
