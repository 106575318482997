export const CLIENT_ID = 'highmark-beneficity-web';
/**
 * @deprecated TEMPORARY while we transition from client-set TenantID to server-set
 */
export const TENANT_ID = 'highmark-beneficity';

// Mobile app URLs
export const APP_STORE_PROD_URL =
  'https://apps.apple.com/us/app/beneficity/id1658570173';
export const PLAY_STORE_PROD_URL =
  'https://play.google.com/store/apps/details?id=com.highmark.beneficity';
