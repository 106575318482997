import React, { SyntheticEvent } from 'react';
import {
  Box,
  Button,
  ParagraphText,
  StackLayout,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import {
  INFO_MODAL_TYPES,
  InfoModalPopover,
} from 'components/background-information/info-modal-popover.component';

export type ExpandableGroupHeaderProps = {
  legend: string;
  isExpanded: boolean;
  handleClick: () => void;
  infoModal?: INFO_MODAL_TYPES;
};

export const ExpandableGroupHeader = ({
  legend,
  isExpanded,
  handleClick,
  infoModal,
}: ExpandableGroupHeaderProps) => {
  const { formatMessage } = useIntl();

  const handleSubClick = (event: SyntheticEvent, handler?: () => void) => {
    event.stopPropagation();
    // Execute event handler if passed one
    if (handler) {
      handler();
    }
  };

  return (
    <StackLayout
      onClick={handleClick}
      orientation="horizontal"
      horizontalAlignment="spaceBetween"
      verticalAlignment="center"
      css={{
        cursor: 'pointer',
        userSelect: 'none',
        paddingTop: '$one',
        paddingBottom: infoModal ? '$quarter' : '$one',
        borderTop: '1px solid $onSurfaceBorderSubdued',
      }}
    >
      <StackLayout orientation="horizontal">
        <ParagraphText css={{ paddingLeft: '$half' }}>
          {formatMessage({ id: legend })}
        </ParagraphText>
        {infoModal && (
          /* Delegate event handling so the popover is activated but the section does not toggle open/closed */
          <Box onClick={handleSubClick}>
            <InfoModalPopover infoModalType={infoModal} />
          </Box>
        )}
      </StackLayout>
      {/* Delegate event handling so the button is keyboard accessible and does not trigger the parent to toggle open/closed */}
      <Button
        onClick={(event: SyntheticEvent) => handleSubClick(event, handleClick)}
        priority="tertiary"
        quiet
        size="large"
        icon={isExpanded ? 'interfaceChevronUp' : 'interfaceChevronDown'}
        hideLabel
        css={{
          marginLeft: '$one',
          position: 'relative',
          bottom: '$quarter',
          padding: '$quarter',
          tint: '$interactiveActionPrimary',
        }}
      >
        {formatMessage({ id: 'BACKGROUND_INFORMATION_TOGGLE' })}
      </Button>
    </StackLayout>
  );
};
