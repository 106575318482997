import * as React from 'react';
import { InputValidationState } from '@leagueplatform/web-common';

/**
 * Copied from `modules/web-common/src/lib/react-hook-form/hooks/use-input-status.hook.ts`
 * and slightly modifed so the error object is passed in instead of
 * it attempting to extract it formState.
 */
export function useInputValidationState(error: any) {
  const inputValidationState: InputValidationState = React.useMemo(() => {
    if (error && typeof error?.message === 'string') {
      return { inputStatus: 'error', statusMessage: error.message };
    }
    return { inputStatus: undefined, statusMessage: undefined };
  }, [error]);

  return inputValidationState;
}
