import {
  Value,
  getBoolean,
  getValue,
  RemoteConfig,
} from 'firebase/remote-config';

export type FeatureFlag = {
  isEnabled: boolean;
  value: Value;
};

export type FeatureFlagProps = {
  flagName: string;
  remoteConfig: RemoteConfig;
};

/**
 * Returns the value of a remote config feature flag
 * @param {object} FeatureFlagProps
 * @returns {object} FeatureFlag object
 */
export const useGetFeatureFlag = ({
  flagName,
  remoteConfig,
}: FeatureFlagProps): FeatureFlag => ({
  isEnabled: getBoolean(remoteConfig, flagName),
  value: getValue(remoteConfig, flagName),
});
