import { useGetIdentityPartnerToken } from 'hooks/use-get-identity-partner-token';
import { DEFAULT_PARTNER_ID, QUERY_KEYS } from 'common/constants';
import { useQuery } from 'react-query';
import { getHealthServices } from 'api/get-health-services';
import { useCurrentRegionUbkHeader } from 'hooks/use-current-region-ubk-header.hook';

const QUERY_CACHE_AND_STALE_TIME = 5 * 1000 * 60;

export const useGetEBillEligibility = () => {
  const { data: identityTokenData } =
    useGetIdentityPartnerToken(DEFAULT_PARTNER_ID);

  const ubkHeader = useCurrentRegionUbkHeader();

  return useQuery(
    [QUERY_KEYS.EBILL_ELIGIBILITY_KEY],
    () => getHealthServices(identityTokenData, ubkHeader),
    {
      enabled: !!identityTokenData,
      staleTime: QUERY_CACHE_AND_STALE_TIME,
      cacheTime: QUERY_CACHE_AND_STALE_TIME,
    },
  );
};
