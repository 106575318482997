import { LEAGUE_MODULE_NAMES, getModulePath } from '@leagueplatform/core';
import { matchPath, match as Match } from '@leagueplatform/routing';
import type { WalletParams } from '@leagueplatform/wallet';
import { getWalletBenefitCard } from '@leagueplatform/wallet-api';
import { getRegionUbkValue } from 'utils/link-handler/get-region-ubk-value';
import { PartnerDefaultOverrideValues } from '../post-message-auth-helpers';

async function getWalletDetailsPageDefaultOverrideValues(
  match: Match<WalletParams>,
) {
  const { benefitId } = match.params;

  try {
    const { client_number: clientNumber } = await getWalletBenefitCard(
      undefined,
      benefitId,
    );
    return { clientNumber };
  } catch {
    throw new Error(
      'Failed to retrieve the client number using the benefit id.',
    );
  }
}

/**
 * This function is deferring the call to `getModulePath` since the
 * route overrides aren't applied immediately.
 * */
export function getPathDefaultOverrideValueMap() {
  const WALLET_DETAILS_ROUTE = `${getModulePath(LEAGUE_MODULE_NAMES.wallet)}/:benefitId`;

  return {
    [WALLET_DETAILS_ROUTE]: getWalletDetailsPageDefaultOverrideValues,
  };
}

/**
 * Iterate over all matched paths and merge the results.
 */
export async function getDefaultOverrideValues() {
  // Iterate over each path and return its corresponding overrides.
  const defaultOverrideValues = await Promise.all(
    Object.entries(getPathDefaultOverrideValueMap()).map(
      ([path, getOverrideValues]) => {
        const match = matchPath(window.location.pathname, {
          path,
        });

        if (match) {
          return getOverrideValues(match);
        }
        return undefined;
      },
    ),
  );

  // Iterate over the list of results and consolidate them into a single object.
  const consolidatedDefaultOverrideValues =
    defaultOverrideValues.reduce<PartnerDefaultOverrideValues>(
      (acc, defaultOverrideValue) => ({
        ...acc,
        ...defaultOverrideValue,
      }),
      {},
    );

  // Get region UBK
  const regionUbkValue = await getRegionUbkValue();

  // Consolidate clientNumber and region UBK data into defaultValues
  return { ...consolidatedDefaultOverrideValues, ...regionUbkValue };
}
