import { useContext, useState } from 'react';
import { RegionSelectionContext } from 'components/header-nav/region-selection-context';
import { useHistory } from '@leagueplatform/routing';
import { useIntl } from '@leagueplatform/locales';
import {
  GDSStatus,
  GDSStyleObject,
  ParagraphText,
  StatusBanner,
  TextAction,
} from '@leagueplatform/genesis-core';
import { QUERY_PARAM_KEYS } from 'common/sso-constants';
import { MODAL_PARAMS } from 'hooks/use-query-modal-content.hook';

type RegionSelectionStatusBannerProps = {
  css?: GDSStyleObject;
};

export const RegionSelectionStatusBanner = ({
  css,
}: RegionSelectionStatusBannerProps) => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const [showBanner, setShowBanner] = useState<boolean>(true);
  const { selectedRegion, regionOptions } = useContext(RegionSelectionContext);
  const regionOptionsLength = regionOptions?.length || 0;
  const planName = selectedRegion?.planName || '';

  if (regionOptionsLength < 2 || !planName || !showBanner) {
    return null;
  }

  const navigateToModal = () => {
    history.push({
      search: `?${QUERY_PARAM_KEYS.modal}=${MODAL_PARAMS.SWITCH_REGION}`,
    });
  };

  return (
    <StatusBanner
      status={'info' as GDSStatus}
      onDismissClick={() => setShowBanner(false)}
      title={formatMessage({ id: 'MULTIPLE_ACTIVE_PLANS' })}
      css={css}
    >
      <ParagraphText size="sm">
        {formatMessage(
          { id: 'MULTIPLE_ACTIVE_PLANS_INFO' },
          { plan: planName },
        )}
      </ParagraphText>
      <TextAction
        as="button"
        onClick={navigateToModal}
        css={{ width: '100px' }}
      >
        {formatMessage({ id: 'SWITCH_VIEW' })}
      </TextAction>
    </StatusBanner>
  );
};
